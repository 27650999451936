import React from 'react'

function Infertility1() {
    return (
        <div>
            <div className='section_heading'>
                <h1>Kalpataru <span>Infertility</span> Treatment</h1>
            </div>

            <div className="container homeabout-main pt-5">

                <div className="row justify-content-between">

                    <div className=" col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 homeabout-col">
                        <p> ➤ Infertility is an emerging health problem that has profound socio-economic and health implications on both the individual and society. Unexplained Infertility is defined as the inability to conceive even after one year with routine investigations of infertility showing no abnormality; with an overall incidence of 10-30%. Ayurveda explains female infertility as Vandhyatva and mentioned Garbha Sambhava Samagri (proper union of four factors like fertile period, healthy reproductive system, nutrition, and healthy ovum and sperm) and Manasika Abhitapa (psychological and emotional factors) as chief factors responsible for conception.</p>
                        <p> ➤ Globally,  Most  Infertile  Couples  suffer  from  primary infertility. It is “a disease of the reproductive system defined by the failure to achieve a clinical pregnancy after 12 months or more of regular unprotected sexual intercourse”.</p>

                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <img className="homeabout-image" src="https://img.freepik.com/free-photo/high-angle-doctor-holding-tablet_23-2149389047.jpg?w=826&t=st=1671465280~exp=1671465880~hmac=5e75fae0cfc97b83c73b9c51e56f48ab3c54e57299544236ed28b65536c931ac" height="290px" alt="Loading.." />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Infertility1