import React from 'react'

function KidneyTwo() {
    return (
        <section className="Kalpataru-second-ortho pt-5">

            <div className="container-fluid">

                <div className="row mt-30">
                    <div className="col-md-3 col-sm-6">
                        <a href=""> <div className="ortho-card">
                            <img src="/images/KidneyCare/KidneyCare1.jpg" style={{ "height": "180px" }} alt="Image" />
                            <div className="ortho-content">
                                <p>Kidney</p>
                            </div>

                        </div></a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href=""><div className="ortho-card">
                            <img src="/images/KidneyCare/KidneyCare2.jpg" style={{ "height": "180px" }} alt="Image" />
                            <div className="ortho-content">
                                <p>Kidney</p>
                            </div>

                        </div></a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href=""><div className="ortho-card">
                            <img src="/images/KidneyCare/KidneyCare3.jpg" style={{ "height": "180px" }} alt="Image" />
                            <div className="ortho-content">
                                <p>Kidney</p>
                            </div>

                        </div></a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href=""><div className="ortho-card">
                            <img src="/images/KidneyCare/KidneyCare4.jpg" style={{ "height": "180px" }} alt="Image" />
                            <div className="ortho-content">
                                <p>Kidney</p>
                            </div>

                        </div></a>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default KidneyTwo