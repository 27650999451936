import React from 'react'

function DiagnosisOne() {
    return (
        <div>
            <div className='section_heading'>
                <h1>Kalpataru <span>IRI Diagnosis</span> Treatment</h1>
            </div>

            <div className="container homeabout-main pt-5">

                <div className="row justify-content-between">

                    <div className=" col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 homeabout-col">
                        <p> ➤ The Iridology course is one among the best and advanced diagnosis systems in the world. This Course is part of the Syllabus for BNYS Students, Nowadays, we are losing our Indian traditional Diagnosis Methods and we are developing a system equal to Modern Science. Our organization from 2012-2014, has been doing hard work strongly on this subject. We gave a presentation at Govt Nature Cure Hospital, Hyderabad on the topic of ‘Iridology Advanced Diagnosis Methods’, and the Honorable Guest Dr. Ajith Singh from London, UK had also given a presentation at Hyderabad in which approximately 300 Naturopathy doctors participated from AP and TS.</p>
                        <p> ➤ Our Organization was the first in India to create awareness and started diagnosis in Telangana & Andhra Pradesh. We have given TV shows on the CVR Health Channel.</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <img className="homeabout-image" src="https://www.spinalcord.com/hs-fs/hubfs/Updated%20Web%20Page%20Images%202020/Types-of-Paralysis.jpg?width=500&name=Types-of-Paralysis.jpg" height="290px" alt="Loading.." />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default DiagnosisOne