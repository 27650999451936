import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Autoplay } from 'swiper';
import Data from "../../../Data/Events/YogaCampOneData.json"
import { Button } from "react-bootstrap";

SwiperCore.use([Autoplay]);

export default function YogaCampOne() {
    return (
        <div className="container">
            <div className="section_heading">
                <h1>Kalpataru <span> Yoga</span> Camps</h1>
            </div>
            <div className="row mb-5 mt-4" >
                <div className="col-xl-6 summer_camp_p" >
                    <p> Introduced by Saint Patanjali, Yoga- a Sanskrit word –is derived from the Sanskrit root ‘Yuj” which means “to control”, “to yoke” or “to unite”. It is a way of life, an art of righteous living, or an integrated system for the body, mind, and inner spirit. This science originated, perfected, and practiced in India thousands of years ago since Pre-Vedic times. Yoga is not just an exercise. It is a process and system through which human beings can find their supreme potential. The moment you attach the word “yoga,” it indicates it is a complete path by itself. </p>
                    <p> The Yoga Sūtras of Patañjali are 196 Indian sutras (aphorisms). The Yoga Sutras were compiled prior to 400 CE by Sage Patanjali, taking materials about yoga from older traditions. Scholars considered the Yoga Sūtras of Patañjali formulations to be one of the foundations of the classical Yoga Philosophy of Hinduism. </p>
                </div>
                <div className="col-xl-6" >
                    <Swiper
                        effect="fade"
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Autoplay, EffectFade]}
                    >
                        {Data.map((d) => (
                            <SwiperSlide className="yoga_camp_slide">
                                <img src={d.image} alt="Loading.." />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        Sri Vyasa, Sri Vachaspati Mishra, and Sri Bhojadeva have given wonderful commentaries about the Patanjali Yoga Sutras, the essence of which is being followed at the Kriya Yoga Samsthan. Patañjali divided his Yoga Sutras into four limbs or quarters (Sanskrit pada).
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        <b> Samadhi Pada(51 sutras): </b> Samadhi refers to a blissful state where the yogi is absorbed into the One.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        <b>Sadhana Pada(55 sutras):</b>  Sadhana is the Sanskrit word for "practice" or "discipline". Sadhana pada advocates Kriya or Karma yoga which is strictly followed at Kriya Yoga Samsthan.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        <b>Kaivalya Pad (34 sutras):</b>   Kaivalya literally means "isolation", but as used in the Sutras stands for emancipation or liberation.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        <b>Vibhuti Pada(56 sutras):</b>  Vibhuti is the Sanskrit word for "power" or "manifestation".
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        SRI PUJYA MAHARSHI SWAMI DAYANANDA SARASWATI was ranked highest among the makers of modern India. He had worked tirelessly for the political, religious, and cultural emancipation of the country. He was guided by reason, taking Hinduism back to the Vedic foundations.
                    </p>
                </div>
            </div>
            <div className="row mb-5" >
                <div className="col-xl-6 col-sm-12 col-xs-12" >
                    <div className="card bg-dark text-white mt-3 ">
                        <img src="images/events/yoga/yogtwo.jpg" className="card-img yoga_img" alt="..." />
                        <div className="card-img-overlay expo_one_row_bg">
                            <h5 className="card-title yoga_title"> Rejuvenation and Yoga Retreat </h5>
                            {/* <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> */}
                            {/* <Button className='expo_one_btn mb-2'> Book Now </Button> */}
                        </div>
                    </div>
                </div>
                <div className="col-xl-6" >
                    <div className="card bg-dark text-white mt-3 ">
                        <img src="images/events/yoga/yogfour.jpg" className="card-img yoga_img" alt="..." />
                        <div className="card-img-overlay expo_one_row_bg">
                            <h5 className="card-title yoga_title"> Blissful Meditation and Yoga Retreat </h5>
                            {/* <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> */}
                            {/* <Button className='expo_one_btn mb-2'> Book Now </Button> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        He had tried to reform society with a clean sweep, which was again needed today. Some of the reforms introduced in the Indian Constitution had been inspired by his teachings. He was a strict practitioner and preacher of Yoga and always went to its Vedic roots seeking only Pure and unadulterated Knowledge.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        He was a staunch follower of particularly Kriya Yoga, which was mentioned in the Sadhana Pada of Patanjali Yoga Sutras, due to which Our Institution was named “Kriya Yoga Samsthan”. Kriya Yoga designates the "practical" aspects of the philosophy, i.e. the "union with the supreme" due to the performance of duties in everyday life.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        If you are passionate about Yoga and you want to make it your world, Kriya Yoga Samsthan Yoga Teachers Training is the perfect way to go. This unique program develops the skills of its students for deeper self-practice and also prepares them for attaining an advanced level of yoga practice and knowledge. The Teachers Training Program at Kriya Yoga Samsthan is an opportunity to offer the possibility to become a vehicle for others to receive the tools necessary to explore their own inner transformation.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        Graduating from KYS yoga teacher training equips you with the skills, knowledge, and degree to start your career in yoga. As a yoga teacher, there are many ways to make a living, and your skills will provide you with the means to be both self-sufficient and entrepreneurial. Besides, Kriya Yoga Samsthan will support you with the required finance, and job placements within India, and the teachers can work under the KYS label with due permission from the authorities. KYS teachers who would like to explore a yoga career abroad will be helped with the required support and placements. Focusing on your goals and intentions can bring you back to the true meaning of your work and help you refocus on your passion. This is the main motive of Kriya Yoga Samsthan which leads to the sure-shot success of the individual.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        The syllabus consists of all that a person would expect from Yoga and beyond. Following the footsteps of Patanjali and Pujya Maharshi Dayanand Saraswati, the course includes Classical Yoga, Therapeutic Yoga, and Practical Yogic solutions to different problems mankind is suffering from, like Spinal disorders, Obesity, Cardio-respiratory problems, Psychiatric problems, Pre-natal and Post-natal Yoga, etc. The approach is practical, professional, and commercial.  Therefore people irrespective of age group, gender, disease, and discipline will compulsorily be benefitted from the Yoga teacher of Yoga Zone of Kriya Yoga Samsthan. Therefore, every student and teacher of KYS Yoga zone attains 100% satisfaction.
                    </p>
                </div>
            </div>
        </div>
    );
}
