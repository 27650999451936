import React from 'react'

function PailsOne() {
  return (
    <div>
      <div className='section_heading'>
        <h1>Kalpataru <span>Hemorrhoids(Piles)</span> Treatment</h1>
      </div>

      <div className="container homeabout-main pt-5">

        <div className="row justify-content-between">

          <div className=" col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 homeabout-col">
            <p> ➤ Hemorrhoids (Arsh) are very common Problem in modern life style. It is very common ailment of rectum affected as much as half of the population by the age 50 year. Hemorrhoids are swollen, inflamed veins and capillaries around the Rectum. This is mainly classified as external and internal piles. In these days many people want to know how to manage piles naturally, easily and fast too. Here in this article we will know the causes that why the disease is so common in today's day to day life & how to manage the disease with minimal interventional procedures such AS <b> KSHAR SUTRA LIGATION, KSHAR KARMA & AGNIKARMA</b>.</p>
            <p> ➤ Hemorrhoids are often described as varicose veins of anus and rectum. These are enlarged, bulging blood vessels in and around anus and lower rectum Hemorrhoids are usually found in three main location.</p>
            <p><b> 1. Left lateral 2. Right anterior 3. Right posterior </b>  </p>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <img className="homeabout-image" src="https://img.freepik.com/free-photo/side-view-man-dealing-with-std_23-2149485559.jpg?w=996&t=st=1671517281~exp=1671517881~hmac=4f68f1ac8baa95268b6ed26c9c46abcaff823fedd69dd17b7f9ec4ba778cab6a" height="290px" alt="Loading.." />
          </div>

        </div>
      </div>
    </div>
  )
}

export default PailsOne