import React from 'react'
import Expo from '../../Container/EventsContainer/Expo'
import Breadcrumb from '../../BreadCrumb/BreadCrumb'
import Footer from '../../Container/Footer/Footer'
import SEO from '../../Container/SEO/SEO'
import TopNav from '../../Container/TopNav/TopNav'
import SummerCampOne from '../../Container/EventsContainer/SummerCampOne'

export default function SummerCamp() {
    return (
        <React.Fragment>
            <SEO title="Kalpataru || Summer Camp" />
            <TopNav />
            <Breadcrumb image="/images/breadcrumb/summercamp1.jpg" title="Home" content="Summer Camp" />
            <SummerCampOne />
            <Footer />
        </React.Fragment>
    )
}
