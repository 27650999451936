import React from 'react'
import HomeServiceData from '../../../Data/HomeData/HomeServiceData.json'

export default function HomeService() {
    return (
        <div className="container  progress-container mt-5">
            <div className="row justify-content-center" >
                <div className="section_heading">
                    <h1>Our  <span>  Treatments </span></h1>
                </div>
            </div>
            <div className="row justify-content-between">
                {
                    HomeServiceData.map((data, key) => (
                        <div key={key} className="col-md-6 col-lg-4 col_center mt-2">
                            <div className="card card-image">
                                <img src={data.image} className="card-img-top homeservice-image" alt="Loading.." />
                                <div className="card-body">
                                    <h5 className="card-title homeservice-title"> {data.title} </h5>
                                    <p className="card-text homeservice-text"> {data.text} </p>
                                    <a href={data.link} className="btn btn-primary homeservice-link "> {data.buttonText} </a>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
