import React from 'react'


export default function Obesity3() {
    return (

        <div>
            <div className="container pt-5">
                <div className="row">
                    <div className="col-md-8">
                        <div className='orthocare-desc'>
                            <h5 className='app-heading'>Causes :- </h5>
                            <ul className='treatment-ortho-ul'>
                                <li> Beta cells destroying our body</li>
                                <li> Hereditary inherited</li>
                                <li> Lack of physical activity</li>
                                <li> Sedentary life (sitting for long time)</li>
                                <li> Non vegetarian food & bakery foods</li>
                                <li> Fried fatty foods</li>
                                <li> Antibiotics used for frequent illnesses</li>
                                <li> High dietary supplements</li>
                                <li> Overweight </li>
                                <li> Alcohol & smoking</li>
                                <li> Excessive consumption of sugary ingredients</li>
                                <li> Mental stress</li>
                                <li> Excessive sex & sleep</li>

                            </ul>
                            <h5 className='app-heading'>Symptoms:- </h5>
                            <ul className='treatment-ortho-ul'>
                                <li> Excessive levels of glucose in the blood </li>
                                <li> Excessive and frequent urination</li>
                                <li> Vision impairment</li>
                                <li> Excessive thirst </li>
                                <li> Tiredness </li>
                                <li> Weight loss</li>
                            </ul>
                            <h5 className='app-heading'>Complications:- </h5>
                            <ul className='treatment-ortho-ul'>
                                <li> Chronic kidney disease is caused by a decrease in blood glucose level </li>
                                <li> Retinal detachment can cause blindness</li>
                                <li> Weakness of nerves</li>
                                <li> Many types of nerves can be damaged </li>
                                <li> Impotency </li>
                                <li> Diabetic ulcer</li>
                                <li> Damage to the kidneys can result in dialysis</li>
                            </ul>
                            <h5 className=" app-heading">Treatment :-</h5>
                            <ul className='treatment-ortho-ul'><li> In modern medicine for diabetes, there is no complete cure except pills and injections, also the use of tablets may damage other organs in the future. </li>
                                <li> Kalpataru ayurveda hospital provides ayurveda therapeutic treatment for diabetes in successive way this includes dietic regimen, ayurveda medicines, diabetic exercises, Musti mardana chikitsa, and Marma chikitsa in these methods controlling the glucose levels in the body. </li>
                                <li> In addition, firstly Kalpataru provides 40 days treatment, them every 4 times in a month. For a year along with ayurvedic treatment should follow dietary regimen, exercises, do’s and don’ts, diabetes comes to full control.</li>
                                <li> Those who use modern medicines are fully adapted to ayurvedic remedies by reducing the dose as the body improves.</li>
                                <li> Kalpataru ayurvedic practices can help people to live happily without suffering that they have diabetes without any side effects.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-6'>
                                <div className="ortho-left-side treat_side_card">
                                    <h4>Make An appointment </h4>
                                    <ul className='treat_ul'>
                                        <li> Monday-Friday <span> : 9:00 AM - 17.00 PM </span></li>
                                        <li>Saturday<span style={{ marginLeft: '42px' }}> : 9:00 AM - 16:00 PM</span></li>
                                        <li>Sunday <span style={{ marginLeft: '51px' }}> : Closed</span></li>
                                        <b> <li>Need Urgent Help ? <span>+919515621818</span></li></b>
                                    </ul>
                                    <div className="appointment-btn pt-10">
                                        <span></span>
                                        <a href="/register" className="main-btn treat_btn ">Enroll Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-6 pt-3'>
                                <div className='ortho-card-secondpart '>

                                    <div className='ortho-image-part'>
                                        <div className='image'>
                                            <img src="/images/Diabetes/diabetes1.jpg" style={{ "height": "200px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Diabetes</h4>
                                        </div>
                                    </div><br />
                                    <div className='ortho-image-part '>
                                        <div className='image'>
                                            <img src="/images/Diabetes/diabetes2.jpg" style={{ "height": "200px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Diabetes </h4>
                                        </div>
                                    </div><br />
                                    <div className='ortho-image-part '>
                                        <div className='image'>
                                            <img src="/images/Diabetes/diabetes3.png" style={{ "height": "200px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Diabetes </h4>
                                        </div>
                                    </div><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>

    )
}
