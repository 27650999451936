import React from 'react'

function Expo() {
    return (
        <div className='container'>
            <div className="section_heading">
                <h1>Kalpataru <span> Ayurveda</span> Expo</h1>
            </div>
            <div className='row justify-content-between mb-3' >
                {/* <div className=' col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12' >
                    <img className='expo_img' src='/images/events/exh.jpg' alt='Loading..' />
                </div> */}
                <div className='col-xl-12 col-lg-8 col-md-12 col-sm-12 col-xs-12 expo_col' >
                    <p>
                        Kriya Yoga Samsthan has been organized and conducted “AYURVEDA EXPO AYURVEDA OUSHADHA MELA” at Hyderabad City and some rural/urban areas in Telangana during the period from 2008 to 2015.Now we are planning to conduct throughout India.
                    </p>
                </div>
            </div>

            <div className='row' >
                <h3 className='about-two-title'> Highlights of the Expo: </h3>
                <div className='col' >
                    <ul className='about-two-ul' >
                        <li>  1.  The Expo demonstrated around 400 Medicinal and Aromatic plants. 250 to 300 Herbs and Herbal Plant varieties were introduced which were considered to be the best in ayurvedic plants. </li>
                        <li>  2.  Medicine from the best Quality pharmacies in India was presented. </li>
                        <li>  3.  Created awareness of Mud Utensils and their importance in a traditional kitchen. </li>
                        <li>  4.  Created awareness of Brass and Copper Utensils and their usage and advantages put forth in the expo.</li>
                        <li>  5.  Free Health Check-ups under the Supervision of experienced Ayurveda Yoga Nature Cure Doctors were conducted.</li>
                        <li>  6.  Demonstrated and sold many ayurvedic products by established Stalls.</li>
                        <li>  7.  At the Expo, we conducted YOGA VIGNANA SHIVIRAM (Yoga Camp) specially designed in accordance with the Kriya Yoga Samsthan syllabus.</li>
                        <li>  8.  We Conducted Health Check-ups using the 5 types of Indian Diagnosis Methods by checking the Tongue, Pulse, Spine, and Face in addition to Iris diagnosis.</li>
                        <li>  9.  Created awareness of “GOU-SAMRAKSHANA” And “GOKARANA NIDHI” Concepts. This concept is very useful for humans and Nature.</li>
                        <li>  10.  Our organization conducts National-level Ayurveda Expos PAN-INDIA, especially in major metropolitan cities.</li>
                        <li>  11.  We also have plans to train and hire 5000 volunteers for the Health sector PAN-INDIA to promote each and every village. Our Volunteers gather their village children in the age groups of 7 to 16 years to motivate health and training. The village people can avail benefit from our services by getting a healthy lifestyle on par with Nature. Our Organization has plans to conduct Monthly Health Camps in villages under the supervision of volunteers. We are also trying to reach our services to remote villages so they can get healthy to fight diseases by leaving their Improper lifestyle and habits.</li>

                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Expo