import React from 'react'

function KneeOne() {
  return (
    <div>
      <div className='section_heading'>
        <h1>Kalpataru <span>Knee Pain</span> Treatment</h1>
      </div>

      <div className="container homeabout-main pt-5">

        <div className="row justify-content-between">

          <div className=" col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 homeabout-col">
            <p> ➤ The knee, a compound synovial joint is the largest weight-bearing joint, thus an injury to the knee adversely affects routine life.</p>
            <p> ➤ Soft tissue injuries are clinically challenging musculoskeletal disorders where accurate and timely diagnosis increases the likelihood of restoring functions and pain-free use of the affected knee. Joint  pain is one of the most frequent complaints which comes before a  medical practitioner.  Joint  pain  is  very  much prevalent  among  elderly  people,  especially  due  to osteoarthritis. Osteoarthritis is a chronic degenerative disorder that mainly affects large and weight-bearing joints like the hip joint, knee joint, spine, etc. Osteoarthritis accounts for 0.6% of all Disability-adjusted life years (DALYs).</p>
            <p> ➤ Knee injuries, in India is estimated to be around 86%. </p>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <img className="homeabout-image" src="https://img.freepik.com/free-vector/arthritis-concept-illustration_114360-7167.jpg?w=740&t=st=1671515058~exp=1671515658~hmac=56ad799b9a5bf60627c5d3191edf5c442dea248d460f1c3e842ab7fc15ae3a7b" height="290px" alt="Loading.." />
          </div>
        </div>
      </div>
    </div>
  )
}

export default KneeOne