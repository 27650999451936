import React from 'react'

export default function Treatment1() {
    return (

        <div>
            <section className="Kalpataru-content1">
                <div className="container">
                    <div className="Kalpataru-ortho">
                        <div className="row ">
                            <div className="col-md-8 order-md-1 order-2">
                                <h4>SCIATICA PAIN:-</h4>
                                <p className="para-text"> ➤ Chronic low back ache (CLBP) prevalence increase linearly from the third decade of life on until the 60years of age. </p>
                                <p className="para-text"> ➤	LBP due to lumbar disc prolapsed is major cause of morbidity throughout the world. Lifetime incidence of LBP is 50-70% with incidence of sciatica more than 40%.</p>
                                <p className="para-text"> ➤  Sciatica is the most common debilitating condition and a serious disease of the nervous system causes CLBP.</p>
                                <p className="para-text"> ➤ Sciatic nerve is the longest and largest nerve in the human body, running from the lower back of the leg (behind the thigh) and down into the muscles of the each leg and feet (toes)</p>
                                <p className="para-text"> ➤ This sciatic nerve runs below the piriformis muscle from greater sciatic foramen and divides into two nerves above the back of the knees, the tibial and the common peroneal nerve, both of which serve the lower leg and foot.</p>
                            </div>
                            <div className="col-md-4 order-1 text-center">

                                <div className="abtImg">
                                    <img src="https://www.brainandspineni.com/wp-content/uploads/sciatica-treatment.jpg" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*     <section className="Kalpataru-second-ortho">

                <div className="container-fluid">

                    <div className="row mt-30">
                        <div className="col-md-3 col-sm-6">
                            <a href=""> <div className="ortho-card">
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSy7umNOD3Bjl5jWQ7Q96YHOpKFGScDohRfQ&usqp=CAU" />
                                <div className="ortho-content">
                                    <p>ArthoCare</p>
                                </div>

                            </div></a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href=""><div className="ortho-card">
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSy7umNOD3Bjl5jWQ7Q96YHOpKFGScDohRfQ&usqp=CAU" />
                                <div className="ortho-content">
                                    <p>ArthoCare</p>
                                </div>

                            </div></a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href=""><div className="ortho-card">
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSy7umNOD3Bjl5jWQ7Q96YHOpKFGScDohRfQ&usqp=CAU" />
                                <div className="ortho-content">
                                    <p>ArthoCare</p>
                                </div>

                            </div></a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href=""><div className="ortho-card">
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSy7umNOD3Bjl5jWQ7Q96YHOpKFGScDohRfQ&usqp=CAU" />
                                <div className="ortho-content">
                                    <p>ArthoCare</p>
                                </div>

                            </div></a>
                        </div>
                    </div>
                </div>

            </section><br /> */}





        </div>
    )
}