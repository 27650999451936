import React from 'react'

function KneeThree() {
    return (
        <div>
            <div class="container pt-5">
                <div class="row">
                    <div class="col-md-8">
                        <div className='orthocare-desc'>
                            <p className="para-text">  ➤ Pain is the main reason for which the patient seeks medical attention and pain management here is of the highest priority as pain is a significant symptom that affects Quality Of Life. The main cause of injury is collision sports, RTA, rapid deceleration, direct blow, and hyperextended knee. The main conservative line of therapy is RICE  (rest, ice, compression, elevation) and grade 3 tears are advised for reconstruction which is very expensive and does process the recurrence of pain.</p>
                            <p className="para-text"> ➤ Ayurveda Treatments are well known for treating musculoskeletal disorders which are known for pain management and they restore normal functions by giving strength to the joint.</p>
                            <div>
                                <h5 class=" app-heading">TREATMENT:-</h5>
                                <p className="para-text">  ➤ Temporary treatment for Knee Pain by modern medical practices is just to reduce the pain temporarily and get relief in the form of tablets, injections, and surgeries. Permanent treatment for Knee pain by Kalpataru Ayurveda is to release the ligament from the improper position and to reduce the space between the synovial fluid spaces by increasing the cartilage by providing the complete ayurvedic treatment in the form of ayurvedic internal medicines and by practicing following procedures.</p>
                                <ul className='treatment-ortho-ul'>
                                    <li> Panchakarma</li>
                                    <li> Upakarmas of panchakarma</li>
                                    <li> Ahara-Niyama (Dietary regimen) </li>
                                    <li> Vyayama (yogic exercise)</li>
                                    <li> Chiropractic</li>
                                    <li> Marma Therapy</li>
                                    <li> Massage Therapy</li>
                                    <li> Consoling</li>
                                </ul>
                            </div>
                            <div className='row mt-2' >
                                <div className='col text-center mb-3 mt-2' >
                                    <h4>Treatment Under Supervision Of  Dr. SRIMNANARAYANA, Ph.D.  B.A.M.S. | M.Sc., M.D. (Ayu) Rasashastra | Ph.D. (Ayu) Rasashastra </h4>
                                </div>
                            </div>

                            <h5 class=" app-heading">CONTRIBUTIONS:-</h5>
                            <p className="para-text">  ➤ For the past 38 years, he has been actively performing conclusive diagnosis with modern tools and applications for various chronic and acute diseases such as CARDIAC, CARCINOMA, Leukemia, Respiratory troubles, ACUTE AND CHRONIC RENAL FAILURE, Liver disorders, Auto-immune diseases such as Psoriasis, Rheumatoid Arthritis, Gout, Gallstones, Renal calculi, Metabolic disorders like Diabetes Mellitus, Thyroidism, Pediatric, and Gynecological disorders, Impotence, Frigidity, INFERTILITY, and other Psychological Ailments.</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-6'>
                                <div className="ortho-left-side treat_side_card">
                                    <h4>Make An appointment </h4>
                                    <ul className='treat_ul'>
                                        <li> Monday-Friday <span> : 9:00 AM - 17.00 PM </span></li>
                                        <li>Saturday<span style={{ marginLeft: '42px' }}> : 9:00 AM - 16:00 PM</span></li>
                                        <li>Sunday <span style={{ marginLeft: '51px' }}> : Closed</span></li>
                                        <b> <li>Need Urgent Help ? <span>+919515621818</span></li></b>
                                    </ul>
                                    <div className="appointment-btn pt-10">
                                        <span></span>
                                        <a href="/register" className="main-btn treat_btn ">Enroll Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-6 pt-3'>
                                <div className='ortho-card-secondpart '>

                                    <div className='ortho-image-part'>
                                        <div className='image'>
                                            <img src="/images/KneePain/KneePain5.jpg" style={{ "height": "180px", "width": "293px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Knee Pain</h4>
                                        </div>
                                    </div><br />
                                    <div className='ortho-image-part '>
                                        <div className='image'>:
                                            <img src="/images/KneePain/KneePain6.jpg" style={{ "height": "180px", "width": "290px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Knee Pain </h4>
                                        </div>
                                    </div><br />
                                    <div className='ortho-image-part '>
                                        <div className='image'>:
                                            <img src="/images/KneePain/KneePain7.jpg" style={{ "height": "180px", "width": "290px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Knee Pain </h4>
                                        </div>
                                    </div><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    )
}

export default KneeThree