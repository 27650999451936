import React from 'react'
import AboutStoreData from '../../../Data/AboutData/AboutStore.json'

export default function AboutStoreOne() {
    return (
        <div className='container' >
            <div className='row' >
                <div className='col' >
                    <div className="section_heading">
                        <h1>Kalpataru <span>Ayurvedic</span> Stores</h1>
                    </div>
                    {/* <h3> Ayurvedic Stores For Every One </h3> */}
                </div>
            </div>
            <div className='row' >
                <div className='col medical-team-col ' >
                    <p>Kalpataru Ayurveda Hospitals provides  quality medicine and general products which we selected from the best and result-oriented above 25-125 years ayurvedic pharmacy which they prepared with the traditional way.Our organization for the past 20 years has been visiting pharmacies throughout India and selecting good combination products which we have mentioned in the list.</p>
                    <p>
                    We have been giving these medications to a number of patients since 2000. We are not interested to give low-quality medicine and treatments to patients. For some complicated chronic health issues,  our experienced doctors give self-prepared medicines with good quality and good medical combinations. 
                    </p>
                    <p>
                    Presently, we are using products from our Gurukul Kangri Pharmacy-Haridwar, Swadeshi Ayurved-,Haridwar, Dhanvantari Pharmacy, Dudpapeswar-Mumbai, Udipiswadesi-Bangalore.United Pharmacy,  Krisha, And Other Pharmacies.
                    </p>
                </div>
            </div>
            <div className='row subheading mt-3'>
                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                                HAIR CARE PRODUCTS
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤ MAHA BRINGARAJ HAIR OIL </li>
                                <li>➤ KALPASOUNDARYA HAIL OIL (SPL)</li>
                                <li>➤ BRAHMI TAILAM</li>
                                <li>➤ HAIR GROWTH POWDER</li>
                                <li>➤ HAIR GROWTH</li>
                                <li>➤ SWADESHI KESARI AMLA RAS</li>
                                <li>➤ AMLA CANDY</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='col-xl-6 '>
                    <div className='row' >
                        <div className='row  mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                                WEIGHT LOSS
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤ MEDHOHARA GUGGULU</li>
                                <li>➤ LAUKI-KA RAS </li>
                                <li>➤ LAUKI –KA GHAN VATI Tab</li>
                                <li>➤ VARUNADI KASHAYAM</li>
                                <li>➤ VARUNADI TABS</li>
                                <li>➤ TRIPHALA RAS </li>
                                <li>➤ NATURE FRESH HONEY </li>
                                <li>➤ NAVAKA GUGGULU </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mt-2 subheading '>
                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                                KIDNEY/GALL BLADDER STONES
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤ MEDICINE KIT  ( SELF- PREPARED)</li>
                                <li>➤ PASHANA VAJRA RAS</li>
                                <li>➤ PRANADA GUTIKA</li>
                                <li>➤ PUNARNAVASAVA</li>

                            </ul>

                        </div>
                    </div>
                </div>

                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                                WOMEN CARE
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤ SUNDARI SUDHA </li>
                                <li>➤ NARI SHAKTI  </li>
                                <li>➤ ASHAOKARISTA </li>
                                <li>➤ PATRAGASAVA </li>
                                <li>➤ PUSPADHANVARAS </li>
                                <li>➤ LODRASAVA </li>
                                <li>➤ PHALAGRITHA  </li>
                            </ul>
                            <ul>
                                <li>➤ OVILIN FORTE  </li>
                                <li>➤ STREE VYADHIHARA RASA  </li>
                                <li>➤ KUNKUMADI TAILAM </li>
                                <li>➤ SATAVARI RAS  </li>
                                <li>➤ KANCHANARA GUGGULU </li>
                                <li>➤ PRADRANTAK LOH </li>
                                <li>➤ RAJOPRAVARDINI VATI</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row  mt-2 subheading'>
                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                                MEN CARE
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤ SHILAJEET CAPSELS</li>
                                <li>➤ ASWAGANDHAVATI</li>
                                <li>➤ ASWAGANDARIST</li>
                                <li>➤ VAJIKARANA RASAYANA SPL</li>
                                <li>➤ MOOSILI PAK (SELF-PREPARED)</li>
                                <li>➤ KONCHBEEJ  ( (SELF-PREPARED) )</li>
                                <li>➤ SUKRAMIN TAB</li>
                                <li>➤ VEERYA VARDANA VATI</li>
                                <li>➤ BRIHAT ASHWAGANDHA GHRITA</li>
                                <li>➤ KAMDUDHA RAS (MUKTA YUKT) </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                                PAIN CARE
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤  RAS SHALLAKI</li>
                                <li>➤ ASTIYOG</li>
                                <li>➤MAHARASNADI KAHADA</li>
                                <li>➤CALCI-O</li>
                                <li>➤ASWAGANDARITSA</li>
                                <li>➤SHALLAKI MR</li>
                                <li>➤YOGRAJ GUGGULU</li>
                                <li>➤SINGNAD GUGGULU</li>
                                <li>➤ASWAGANDA GHAN VATI</li>

                            </ul>
                            <ul>
                                <li>➤KSHEERABALA DS</li>
                                <li>➤VATAGAJANKUSH RAS</li>
                                <li>➤VATARIN TABS</li>
                                <li>➤YOGENDRA RAS</li>
                                <li>➤BRIHAT VATA CHINTAMANI RAS</li>
                                <li>➤VATA GAJANKUSHA RAS</li>
                                <li>➤RASRAJ RAS</li>
                                <li>➤LOHASAVA</li>
                                <li>➤MAHAVATA VIDVAMSA RAS</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row  mt-2 subheading'>
                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                                MOTHER AND CHILD CARE
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤ NARI SAKTII</li>
                                <li>➤ SUNDARI SUDHA</li>
                                <li>➤ PARTANGASAVA</li>
                                <li>➤ BALANTAKADA</li>
                                <li>➤ BALGUTTI</li>
                                <li>➤ LODRASAVA</li>
                                <li>➤ SATAVARI</li>
                                <li>➤ SWARNA BINDU</li>
                                <li>➤ PUSPADHAVARAS</li>
                                <li>➤ HEMOGLOBIN BOOSTER </li>
                                <li>➤ ASHOKARISTA </li>
                                <li>➤ PUSYANAGA CHURNA </li>

                            </ul>
                        </div>
                    </div>
                </div>

                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                                DIABETIC CARE
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤ MADHU MEHA NASINI GUTIKA</li>
                                <li>➤ VASANTA KUSUMAKARA RAS</li>
                                <li>➤ TRIPALA RAS</li>
                                <li>➤ NEEM HONEY</li>
                                <li>➤ SWARNAPRABHAVATI</li>
                            </ul>
                            <ul>
                                <li>➤ CHANDRAPRABHAVATI</li>
                                <li>➤ KARELA RAS</li>
                                <li>➤ KARELA JAMUN RAS</li>
                                <li>➤ NEEM KARELA JAMUN RAS</li>
                                <li>➤ NETRAMRITAM</li>
                                <li>➤ TRIVANGABHASAM</li>
                                <li>➤ SHUDDAH SHILAJEET</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row  mt-2 subheading'>
                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                                PAIN OILS
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤ MAHA NARAYANA TAILAM</li>
                                <li>➤ VATORIN OIL</li>
                                <li>➤ MAHA VISHAGHARBA TAILAM</li>
                                <li>➤ DARDHARA TAILAM</li>
                                <li>➤ DARD VIMUKTI TAIALM (SELF-PREPARED)</li>

                            </ul>
                        </div>
                    </div>
                </div>

                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                                BRAIN CARE
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤ SARASWATI ARISTA </li>
                                <li>➤ BRAHMI RASAYAN </li>
                                <li>➤ MEDHAVARDINI (SELF-PREPARED) </li>
                                <li>➤ SWARNABINDU GOLD DORPS </li>
                            </ul>
                            <ul>
                                <li>➤ BRAHM VATI </li>
                                <li>➤ BRAHMI GRITAM </li>
                                <li>➤ SWARNA MADHU </li>
                                <li>➤ KAMAL MADHU  </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
            <div className='row  mt-2 subheading'>
                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                                PILES & FISTULA
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤ SAPTVISHANTI GUGGULU </li>
                                <li>➤ ARSHKUTUR RAS </li>
                                <li>➤ ABHAYARISTA </li>
                                <li>➤ CHANDANASAVA </li>
                                <li>➤ HARITAKI CHURNAM </li>
                                <li>➤ VIRECHANA VATI </li>
                                <li>➤ SUKABHEDI RAS </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                                SKIN CARE
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤ KADHIRARSTA  </li>
                                <li>➤ NEEM GHANAVATI </li>
                                <li>➤ NEEMOL TONIC </li>
                                <li>➤ KARANJI TAILAM</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
            <div className='row  mt-2 subheading'>
                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                            DIGESTIVE CARE  
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤ AROGYA VARDINI VATI  </li>
                                <li>➤ ARAVINDIASAVA  </li>
                                <li>➤ USHIRASAVA</li>
                                <li>➤ CHITRAKADIVATI</li>
                                <li>➤ PANCHAMRITASAVA </li>
                                <li>➤ TRIPHALA RAS </li>
                                <li>➤ PACHAN SUDHA </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                            LIVER CARE  
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤ YAKRITH SHODAN </li>
                                <li>➤ UDARAMRITHA</li>
                                <li>➤ KUMARYASAVA  </li>
                                </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row  mt-2 subheading'>
                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                            BLOOD PRESSURE
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤ UNCH RAKTACHAPAVATI</li>
                                <li>➤ BRAHMI RASAYAN</li>
                                <li>➤ SARPAGANDAVATI</li>
                                <li>➤ CHANDANA SAVA</li>
                                <li>➤ BRAHMI VATI</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                            DENTAL/EYE/EAR/NOSE  
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤ PAYOKIL TOOTH POWDER  </li>
                                <li>➤ KAJAL </li>
                                <li>➤ SAPTAMRITA LOHA </li>
                                </ul>
                                <ul>
                                <li>➤ SARIVADIVATI </li>
                                <li>➤ KARNA BINDU</li>
                                <li>➤ NETRA BINDU</li>
                                <li>➤ SHADBINDU</li>
                                </ul>
                        </div>
                    </div>
                </div>
            </div><br/>

        </div>
    )
}
