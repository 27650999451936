import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import AboutOneData from "../../../Data/AboutData/AboutOne.json"

export default function AboutOne() {
    return (
        <Container fluid className='about-one-container-one' >
            <Container className="about-one-container" >
                <Row>
                    {
                        AboutOneData.map((data, key) => (
                            <div key={key} className="col-md-6 col-lg-4 col_center mt-2">
                                <div className="card about-one-card">
                                    <div class="card-header about_card_header_text">
                                        {data.title}
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title homeservice-title"> {data.subtitle} </h5>
                                        <p className="card-text homeservice-text"> {data.text} </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                </Row>
            </Container>
        </Container>
    )
}
