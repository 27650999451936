import React from 'react'

function DiagnosisTwo() {
    return (
        <div>
            <div className="container pt-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className='orthocare-desc'>
                            <p className="para-text">  ➤ A Malignant tumor does not suppurate because it contains an excess of Kapha and adipose tissue, thus forming firm little ballsWe always encourage urban and rural doctors for these tests. We successfully completed around 2000 Reports in AP and TS. The test results were 100 % accurate. But there is a shortage of manpower for system development in India. The diagnosis shows the present and future diagnoses of upcoming diseases like diabetes, cancer, kidney, and heart-related problems, as also common problems. We are planning to establish all over India diagnosis centers for public health support depending on the diagnosis. BNYS and BAMS doctors will provide diet charts, medicines, and treatment.</p>

                            <div>
                                <h5 className=" app-heading">IRIDIAGNOSIS:-</h5>
                                <p className="para-text">  ➤ In Ayurveda Medical System, Iris diagnosis is vital, as it diagnoses the possible chronic diseases that may occur in the next 6 months. In a global conference of doctors in 2012, we demonstrated iris diagnosis tests that included pulse, saffron, face, etc., and even launched an iris diagnosis center for an early diagnosis and required treatment. Efforts are afoot to re-launch the book on “slowness” authored by Dr.Mruthunti Anjaneyulu, as it contains a wealth of medical information that benefits society at large.</p>
                            </div>

                            <div>
                                <h5 className=" app-heading">IRIDIAGNOSIS TESTS AND ITS IMPORTANCE:-</h5>
                                <p className="para-text">  ➤ We are proud to announce to you IRIDOLOGY and its importance in our field. Coming to IRIDOLOGY, as you all are aware of the subject and its use in present day-to-day life.</p>
                                <p className="para-text">  ➤ IRIDOLOGY mainly deals with the body's constitution, inherent strengths and weaknesses, health levels, and transitions that take place in a person’s body according to their way of life. It is a very much helping tool for diagnosis as per medical science.</p>
                                <p className="para-text">  ➤ Dr. John Andrews and Dr. Ajit Singh are  recognized doctors from the UK working in the field of IRIDOLOGY and its research for the past 10 to 25 years. John Andrews was actively involved in several IRIDOLOGY research initiatives in the UK and across the globe. His contribution to the advancement of IRIDOLOGY was internationally recognized in BRAZIL in 2004 with the presentation of the “IGNATZ VONPECZELY” award. In June 2007, John was honored with the “IRIDOLOGIST OF THE YEAR 2007” award in the USA. Dr. Ajit Singh has been working in the field of SPAGYRICS for the last 10 years and has examined the process in-depth resultantly finding that it’s quite possible to balance all types of sickness by giving the rightly selected herbs. He also revived and introduced the unique art of diagnosis of “IRIS ANALYSIS “in INDIA with the help of world-renowned IRIDOLOGIST “Mr. John Andrew. This subject was often discussed with Dr.FABIO AMBROSI, NATUROPATH from ITALY to consider and spread it as the way of diagnosis in the medical system.</p>
                                <p className="para-text">  ➤ Now in India, this IRIDIAGNOSIS is first introduced by Dr. Ajith Singh through ONLINE SERVICES.</p>
                                <p className="para-text"> Presently, Throughout India Kalpataru Ayurveda Hospitals is introducing and providing services with INTERNATIONAL STANDARDS. It is a scientific analysis of patterns and structures in the iris of the eye which locates areas and stages of inflammations throughout the body. Based on this diagnosis report our qualified and experienced doctors to advise diet modification, ayurvedic medicine, exercise and yoga, Nature cure treatments, Ayurveda  Homeopathy, Unani, and Siddha according to the disorders. Through this, Kalpataru Ayurveda Hospitals are providing all the services throughout INDIA at your Clinics and Hospitals. If any practitioner is interested to practice by commercial source, a franchise will be provided. So we kindly request you to respect the “Traditional Indian health system of medicine” which is useful to the common public. </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mt-2' >
                    <div className='col text-center mb-3 mt-2' >
                        <h4 className='mb-4'>FOR MORE DETAILS: DOWNLOAD BROUCHER  </h4>
                        <a className='diagosis_link mt-4' href="/images/Diagnosis/iris.pdf" target="_blank" rel="noopener noreferrer">Download Brochure</a>
                    </div>
                </div>

            </div>
            <br />
        </div>
    )
}

export default DiagnosisTwo