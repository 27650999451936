import React from 'react'
import { Button, Col, Row, Table } from 'react-bootstrap'
import Data from "../../../Data/Events/YogaCampTwoData.json"

function YogaCampTwo() {
    return (
        <div className='container' >

            <Row>
                <Col> <h6 className="about-two-title" >For Kriya Yoga Samstahan’s Teachers Training Certification</h6> </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <Table style={{ border: '0.2px solid gray' }} responsive>
                        <thead>
                            <tr>
                                <th>Courses</th>
                                <th>Short Term KYSYTTC</th>
                                <th>Long Term KYSYTTC</th>
                                <th>International YTTC</th>
                                <th>Yoga Therapy TTC</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Duration</td>
                                <td>3 Months</td>
                                <td>9 Months</td>
                                <td>9 Months</td>
                                <td>3-9 Months</td>
                            </tr>
                            <tr>
                                <td>Fee Structure</td>
                                <td>20,000/- INR</td>
                                <td>35,000/- INR</td>
                                <td>1,00,000/- INR</td>
                                <td>20K-35K INP</td>
                            </tr>
                            <tr>
                                <td>Timings</td>
                                <td>6:00AM to 8:00PM</td>
                                <td>6:00AM to 8:00PM</td>
                                <td>6:00AM to 8:00PM</td>
                                <td>6:00AM to 8:00PM</td>
                            </tr>
                            <tr>
                                <td>Internship</td>
                                <td>45 Days</td>
                                <td>135 Days</td>
                                <td>135 Days</td>
                                <td>45-135 Days</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                {/* <Col xl={6} className="mb-5">
                    <img className='yoga_camp_img' src='images/events/yoga/yogafive.jpg' alt='Loading..' />
                </Col> */}
            </Row>
        </div>
    )
}

export default YogaCampTwo