import React from 'react'

function SwarnaBinduTwo() {
    return (
        <div className='container' >
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        Today our company with the name of CHILDREN’S HEALTH ZONE, to stay away from chronic diseases, state wide and zonal level, appointed health advisors with the name of SWARNA PRASHANA, BRAHMA PRASHANA, AUSHADHA PRASHANA recognizing the disease with in the early stage and working hard to increase the MEDHA GRAHANA, SHRUTA DHARUTVA CAPACITY.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        <b>SWARNA BINDU DROPS AVAILABLE:</b> KALPATARU AYURVEDA HOSPITALS AND ALL COLLABORATION CENTERS IN INDIA.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SwarnaBinduTwo