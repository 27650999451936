import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'
import Treatment1 from '../Container/Treatments/Treatment1'
import Treatment2 from '../Container/Treatments/Treatment2'
import Treatment3 from '../Container/Treatments/Treatment3'

export default function SciaticaPain () {
    return (
       
      <React.Fragment>
      <SEO title="Kalpataru || Sciatica Pain "  />
      <TopNav/>
      <Breadcrumb image="/images/breadcrumb/SciaticaPain.jpg" title="Home" content="Sciatica Pain"/>
      <Treatment1/>
      <Treatment2/>
      <Treatment3/>
      <Footer/>
     </React.Fragment>

       
    )
}
