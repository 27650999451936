import React from 'react';

export default function Neck4() {
    return (
        <div>
            <div class="container pt-5">
                <div class="row">
                    <div class="col-md-8">
                        <div className='orthocare-desc'>
                            <h5 class=" app-heading">About NeckPain :-</h5>
                            <p className="para-text">  ➤ Your neck is flexible & supports the weight of your head, so it can be vulnerable to injuries and conditions that cause neck pain & restrict motion.</p>
                            <p className="para-text">  ➤ 	Cervical spondylosis occurs due to the narrowing of the space between the cervical vertebrae resulting in the compression of the nerves.</p>
                            <p className="para-text">  ➤ Sandwiched between these two bony blocks are cushions of cartilage & elastic tissues called intervertebral discs, which act as shock absorbers for the back & mobility would be impossible without vertebral discs.</p>
                            <h5 className='app-heading'>Common conditions causing neck pain are :- </h5>
                            <ul className='treatment-ortho-ul'><li>Degenerative disc disease</li>
                                <li>Osteoarthritis</li>
                                <li>Cervical spondylosis</li>
                                <li>Spinal Stenosis</li>
                                <li>Herniated disc</li>
                                <li>Cervical radiculopathy (root compression)</li>
                                <li>Cervical myelopathy (cord compression)</li>
                            </ul>

                            <h5 className='app-heading'>Causes :- </h5>
                            <ul className='treatment-ortho-ul'><li>Neck injury caused by an accident. </li>
                                <li>Faulty posture (improper back & neck posture)</li>
                                <li>Incorrect nutrition resulting from dietic errors.</li>
                                <li>Psychological emotional strain may cause painful muscle cramps.</li>
                                <li>Stress & strain resulting from sitting for a long time.</li>
                                <li>Improper lifting of weights.</li>
                                <li>Physical inactivity</li>
                                <li>Texting etc.</li>
                            </ul>
                            <h5 className='app-heading'>Symptoms :-</h5>
                            <ul className='treatment-ortho-ul'><li>Nagging & severe neck pain may radiate to shoulders with headache (posterior occipital region), back neck, and the collar bone.</li>
                                <li>Dysesthesiae in hand, weakness and clumsiness of hand ( alone or in combination)</li>
                                <li>Acute or chronic stiffness, may lead to partial or complete restricted movements.</li>
                                <li>Numbness and tingling sensation or complete loss of sensation on the affected side.</li>
                                <li>Headache & giddiness</li>
                                <li>Occasionally, there may be muscle weakness of the arm or hand.</li>
                            </ul>

                            <h5 class=" app-heading">Treatments :-</h5>
                            <p className="para-text">Kalpataru ayurveda offers treatment for neck pain in 4 to 5 different methods which helps to regenerate the synovial fluid in the cartilage of vertebrae joints, indirectly decreases the disc bulges and automatically releases the pinched nerve, which finally manages the pain. It includes</p>
                            <ul className='treatment-ortho-ul'><li>•	Ayurveda panchakarma and its upakramas like snehana (bahirparimarjana chikitsa like Abhyangam, pradeha, pariseka etc and antahparimarjana chikitsa like internal administration of snehana through mouth and vasthi through anus) and Swedana, greeva vasti, saravangadhara with taila and kashaya, Nasya.</li>
                                <li>Dietary regimen to increase the immunity and to maintain the Dhatus</li>
                                <li>Ayurvedic internal medicine</li>
                                <li>Panchakarma</li>
                                <li>Upakarmas of panchakarma</li>
                                <li>Ahara niyama (dietary regimen)</li>
                                <li>Vyayama (yogic exercise)</li>
                                <li>Chairopractic</li>
                                <li>Marma Therpay </li>
                                <li>Massage Therapy </li>
                                <li>Consoling </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-6'>
                                <div className="ortho-left-side treat_side_card">
                                    <h4>Make An appointment </h4>
                                    <ul className='treat_ul'>
                                        <li> Monday-Friday <span> : 9:00 AM - 17.00 PM </span></li>
                                        <li>Saturday<span style={{ marginLeft: '42px' }}> : 9:00 AM - 16:00 PM</span></li>
                                        <li>Sunday <span style={{ marginLeft: '51px' }}> : Closed</span></li>
                                        <b> <li>Need Urgent Help ? <span>+919515621818</span></li></b>
                                    </ul>
                                    <div className="appointment-btn pt-10">
                                        <span></span>
                                        <a href="/register" className="main-btn treat_btn ">Enroll Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-6 pt-3'>
                                <div className='ortho-card-secondpart '>

                                    <div className='ortho-image-part'>
                                        <div className='image'>
                                            <img src="/images/Neckpain/sideneck1.jpg" style={{ "height": "200px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Neck pain</h4>
                                        </div>
                                    </div><br />
                                    <div className='ortho-image-part '>
                                        <div className='image'>
                                            <img src="/images/Neckpain/sideneck2.jpg" style={{ "height": "200px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Neck pain </h4>
                                        </div>
                                    </div><br />
                                    <div className='ortho-image-part '>
                                        <div className='image'>
                                            <img src="/images/Neckpain/sideneck3.jpg" style={{ "height": "200px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Neck pain</h4>
                                        </div>
                                    </div><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>


    )
}
