import React from 'react'

function KidneyThree() {
    return (
        <div>
            <div class="container pt-5">
                <div class="row">
                    <div class="col-md-8">
                        <div className='orthocare-desc'>
                            <h5 class=" app-heading">KIDNEY STONE</h5>
                            <p className="para-text">
                                ➤ Kidney stone is one of the most common diseases of the urinary system. It has peculiar tendency of recurrence even after surgery. Kidney stones are a result of more minerals or salts in urine. Various factors like low fluid intake, high dietary intake of animal protein, sedentary life style, nutritional deficiency (vitamin A), geography, metabolic disorders (like gout), etc. may be responsible for the formation of kidney stones. It occurs in approximately 12% of the world population and its re-occurrence rate in males is 70-81% and 47-60% in female.2 Symptoms of kidney stones include difficulty, pain and burning sensation in passing urine, acute pain in lower abdominal region, and sometimes vomiting sensation, fever and hematuria (blood in urine).
                            </p>

                        </div>

                        <div className='orthocare-desc'>
                            <h5 class=" app-heading">What Are The Types Of Kidney Diseases?</h5>
                            <p className="para-text">  ➤ CHRONIC KIDNEY DISEASE (CAD) </p>
                            <p className="para-text">  ➤ KIDNEY STONES. </p>
                            <p className="para-text">  ➤ GLOMERULONEPHRITIS. </p>
                            <p className="para-text">  ➤ POLYCYSTIC KIDNEY DISEASE  </p>
                            <p className="para-text">  ➤ URINARY TRACT INFECTIONS   </p>
                        </div>
                        <div className='row mt-2' >
                            <div className='col text-center mb-3 mt-2' >
                                <h4>Treatment Under Supervision Of  Dr. SRIMNANARAYANA, Ph.D.  B.A.M.S. | M.Sc., M.D. (Ayu) Rasashastra | Ph.D. (Ayu) Rasashastra </h4>
                            </div>
                        </div>
                        <div className='orthocare-desc'>
                            <h5 class=" app-heading">CONTRIBUTIONS:</h5>
                            <p className="para-text">
                                ➤ For the past 38 years, he has been actively performing conclusive diagnosis with modern tools and applications for various chronic and acute diseases such as CARDIAC, CARCINOMA, Leukemia, Respiratory troubles, ACUTE AND CHRONIC RENAL FAILURE, Liver disorders, Auto-immune diseases such as Psoriasis, Rheumatoid Arthritis, Gout, Gallstones, Renal calculi, Metabolic disorders like Diabetes Mellitus, Thyroidism, Pediatric, and Gynecological disorders, Impotence, Frigidity, INFERTILITY, and other Psychological Ailments
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-6'>
                                <div className="ortho-left-side treat_side_card">
                                    <h4>Make An appointment </h4>
                                    <ul className='treat_ul'>
                                        <li> Monday-Friday <span> : 9:00 AM - 17.00 PM </span></li>
                                        <li>Saturday<span style={{ marginLeft: '42px' }}> : 9:00 AM - 16:00 PM</span></li>
                                        <li>Sunday <span style={{ marginLeft: '51px' }}> : Closed</span></li>
                                        <b> <li>Need Urgent Help ? <span>+919515621818</span></li></b>
                                    </ul>
                                    <div className="appointment-btn pt-10">
                                        <span></span>
                                        <a href="/register" className="main-btn treat_btn ">Enroll Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-6 pt-3'>
                                <div className='ortho-card-secondpart '>

                                    <div className='ortho-image-part'>
                                        <div className='image'>
                                            <img src="/images/KidneyCare/KidneyCare7.jpg" style={{ "height": "180px", "width": "293px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Kidney</h4>
                                        </div>
                                    </div><br />
                                    <div className='ortho-image-part '>
                                        <div className='image'>:
                                            <img src="/images/KidneyCare/KidneyCare6.jpg" style={{ "height": "180px", "width": "290px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Kidney </h4>
                                        </div>
                                    </div><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    )
}

export default KidneyThree