import React from 'react'

function ShishuSwarnaOne() {
    return (
        <div className='container' >
            <div className="section_heading">
                <h1>SHISHU <span> SAMSKARA </span> MAHA YAGNA</h1>
            </div>
            <div className='row mb-5' >
                <div className='col-xl-6 summer_camp_p' >
                    <p>
                        We are in a modern era where we have entirely forgotten vaidika sanskara procedures. These were taught by our ancient sages and disappearing right now. We follow those which are coming from our lineages because we don't know the correct essence.
                    </p>
                    <p>
                        Maharshi Dayanand Saraswati Kriya yoga Sansthan has taken a great initiative to get back to that ancient wisdom and practices. Our organization feels that our renowned sages' ancient knowledge has to be given in the correct way to our future generation.
                    </p>
                    <p>
                        So to spread awareness of our Vedic culture in this modern era, Sishu Sanskara Homa is performed every month on PUSHYA NAKSHATRA DAY.
                        Through the homa, it is said Agni deva is a mediator between the divine and us to carry our prayers. Hence homa is a powerful ancient ritual. For the first time in India, in Hyderabad, Telangana, we have taken up this massive project.
                    </p>
                    <p>
                        For the past 5000 years, India has lost the Vedic culture due to Western civilization's influence. The seeds sown in our land by British people were still in there us though we take pride in telling we achieved freedom. In the past 75 years, only a few great ones were born in this India who are concerned about society.
                    </p>
                </div>
                <div className='col-xl-6' >
                    <img className='yoga_camp_img' src='/images/events/shishu.jpeg' />
                </div>
            </div>
        </div>
    )
}

export default ShishuSwarnaOne