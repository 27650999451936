import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'
import KidneyOne from '../Container/KidneyContainer/KidneyOne'
import KidneyTwo from '../Container/KidneyContainer/KidneyTwo'
import KidneyThree from '../Container/KidneyContainer/KidneyThree'


export default function KidneyCare() {
  return (
    <React.Fragment>
      <SEO title="Kalpataru || KidneyCare" />
      <TopNav />
      <Breadcrumb image="/images/breadcrumb/KidneyCare.jpg" title="Home" content="KidneyCare" />
      <KidneyOne />
      <KidneyTwo />
      <KidneyThree />
      <Footer />
    </React.Fragment>


  )
}
