import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'
import Back1 from '../Container/Backpaintreatment/Back1'
import Back2 from '../Container/Backpaintreatment/Back2'
import Back3 from '../Container/Backpaintreatment/Back3'
import Back4 from '../Container/Backpaintreatment/Back4'

export default function ArthoCare() {
  return (
    <React.Fragment>
      <SEO title="Kalpataru || BackPain" />
      <TopNav />
      <Breadcrumb image="/images/breadcrumb/backapain.jpg" title="Home" content="BackPain" />
      <Back1 />
      <Back2 />
      <Back3 />
      <Back4 />
      <Footer />
    </React.Fragment>


  )
}
