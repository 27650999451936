import React from 'react'

export default function About() {
    return (
        <div className="container homeabout-main">
            <div className="row" >
                <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12" >
                    <div className="section_heading">
                        <h1>Kalpataru <span>Ayurveda</span></h1>
                    </div>
                    {/* <h3> Ayurvedic Stores For Every One </h3> */}
                    {/* <h3 className="about-title text-center" > Kalpataru Ayurveda </h3> */}
                </div>
            </div>
            <div className="row justify-content-between mb-2">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <img src="/images/about/herb.jpg" className="homeabout-image" alt="..." />
                </div>
                <div className=" col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 homeabout-col">
                    <p>From the past 20 years, Kalpataru Ayurveda hospitals researched and developed different kinds of techniques in the treatment of spine/bone/pain related to Neuro-muscular disorders like Lumbar spondylosis (Low back ache), Cervical spondylosis (Neck pain), Sciatica (Radiating pain), Spinal Muscular Atrophy, Ankylosing Spondylitis, Lumbar Spinal Stenosis, Degenerative changes of the spine, Osteoarthritis (Knee joint pain), Rheumatoid arthritis (slight joint pain), Metabolic arthritis (Gouty).</p>
                    <p> Apart from this, Kalpataru Ayurveda also treats metabolic disorders like Obesity (deposited fat), Diabetes (insulin-dependent), and hypertension. Female related like Hair and scalp-related disorders, Menstrual disorders, Infertility, Thyroid, and IBS (Abnormal abdominal pain, Cramping, Bloating, Diarrhoea, constipation, and Acidity).</p>
                    <p>Childcare (Immunity, Memory loss, Lack of Appetite, Positive energy, Viral infections Hyperactivity, anxiety, children’s physical and mental health).</p>
                </div>
            </div>
            <div className="row justify-content-between mb-2">
                <div className='col' >
                    <p> For 20 years, KYS Ayurveda hospitals treated all the above disorders in a successive pattern with different techniques in different areas all over India. For the convenience of the patient’s treatment services, KYS Ayurveda established its centers in various places of Telangana & Andhra Pradesh along with this, now our organization is planning to establish its centers in different states of India.</p>
                </div>
            </div>
        </div>
    )
}
