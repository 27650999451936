import React from 'react'

function PailsThree() {
    return (
        <div>
            <div class="container pt-5">
                <div class="row">
                    <div class="col-md-8">
                        <div className='orthocare-desc'>
                            <p className="para-text">  ➤ It may better understand by three positions 3o’ Clock, 7o’ Clock and 11o’ Clock on watch. They lie beneath the epithelial lining of anal canal and consist of direct arteriovenous communications, mainly between the terminal branches of superior rectal and superior hemorrhoid arteries and to a lesser extent between branches originating from the inferior and middle hemorrhoid arteries and surrounding connective tissue.</p>

                            <div>
                                <p>They are mainly classified as </p>
                                <ul>
                                    <li> <b> 1. INTERNAL PILES</b> </li>
                                    <li><b> 2. EXTERNAL PILES </b></li>
                                </ul>
                            </div>
                            <div>
                                <h5 class=" app-heading">CLASSIFICATION:-</h5>
                                <p className="para-text">  ➤ On the basis of positions 1. Internal 2. External 3. Interno external (B) On the basis of symptoms 1. Grade 1= No prolapse, only prominent blood vessels. 2. Grade 2= prolapse upon bearing down but spontaneously reduces. 3. Grade 3= prolapse upon bearing down and require manual reduction. 4. Grade 4= prolapse and cannot be even manually reduced.</p>
                            </div>
                            <div>
                                <h5 class=" app-heading">SYMPTOMS:-</h5>
                                <p className="para-text">  ➤ The blood typically come with the stool, The condition known as hematochezia, is on the toilet paper, or drops on the toilets. Other symptoms may include mucus discharge, a perianal mass prolapses through the anus, itching, fecal incontinence. Internal piles are usually only painful if they become thrombosed or necrotic. External hemorrhoids These are also not painful, however when thrombosed they may be very painful. pain may resolve over 2 to 3 days. Swelling may take few weeks to disappear. Some time there is poor hygiene it may cause itching and irritation too. Interno-external There is mixed symptoms of internal and external piles. </p>
                            </div>

                            <div>
                                <h5 class=" app-heading">TREATMENT:-</h5>
                                <p className="para-text">  ➤ <b>INFRA-RED COAGULATOR- </b> are used to cure the disease but they all are the modified form of Agnikarm Which was described by “ACHARYA SUSHRUTA” decades ago. In 3 and 4 degree Piles the following ayurvedic treatments are available. </p>
                                <p className="para-text">  ➤ <b>KSHAR SUTAR LIGATION- </b> it is a very effective procedure for hemorrhoids in which the medicated herbal coated thread is ligated on the external and internal piles mass. And after 5 to 7 days piles mass falls off. Success rate is 97%. </p>

                                <p className="para-text">  ➤ <b>KSHAR KARAM- </b>  in this procedure kshar is applied on the pile mass and washed with the lemon water. The sclerosing agent like Apamarg Kshar and Snuhi Kshar is use to do the procedure. success rate is 70%. </p>
                                <p className="para-text">  ➤ <b>AGNIKARM- </b> this procedure can be done with the electro cautri, infra red radiation, laser surgery, cryo surgery is done in first and second degree. In the third and fourth degree there is re occurrence chances. </p>
                                <p className="para-text"> ➤ <b>CHEDAN KARAM-</b> this is used if conservative treatment fails. There are number of surgical excision techniques which are used for piles. All are associated with some degree of complication like infection, bleeding, anal strinctures, and urine retention. There may be small risk of fecal in continence. Benefit of kshar sutra therapy 1. Day care surgery does not need admission. 2. Useful in all ages and even in cardiac patients. 3. Simple procedures, safe and sure treatment 4. No Complications 5. No Re-Occurrence 6. Very High success rate 7. Not very costly 8. No antibiotics require </p>
                            </div>

                            <div className='row mt-2' >
                                <div className='col text-center mb-3 mt-2' >
                                    <h4>TREATMENT UNDER SUPERVISION OF DR UPENDER KATTA MS.SHALYA  </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-6'>
                                <div className="ortho-left-side treat_side_card">
                                    <h4>Make An appointment </h4>
                                    <ul className='treat_ul'>
                                        <li> Monday-Friday <span> : 9:00 AM - 17.00 PM </span></li>
                                        <li>Saturday<span style={{ marginLeft: '42px' }}> : 9:00 AM - 16:00 PM</span></li>
                                        <li>Sunday <span style={{ marginLeft: '51px' }}> : Closed</span></li>
                                        <b> <li>Need Urgent Help ? <span>+919515621818</span></li></b>
                                    </ul>
                                    <div className="appointment-btn pt-10">
                                        <span></span>
                                        <a href="/register" className="main-btn treat_btn ">Enroll Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-6 pt-3'>
                                <div className='ortho-card-secondpart '>

                                    <div className='ortho-image-part'>
                                        <div className='image'>
                                            <img src="/images/Piles/Piles7.png" style={{ "height": "180px", "width": "293px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Hemorrhoids(Piles)</h4>
                                        </div>
                                    </div><br />
                                    <div className='ortho-image-part '>
                                        <div className='image'>:
                                            <img src="/images/Piles/Piles6.jpg" style={{ "height": "180px", "width": "290px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Hemorrhoids(Piles) </h4>
                                        </div>
                                    </div><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    )
}

export default PailsThree