import React from 'react'
import { Card } from 'react-bootstrap'

function ApprochUs() {
    return (
        <div className='container-fluid mt-5'>
            <div className="row justify-content-center" >
                <div className="section_heading">
                    <h1> Online <span> Consultation  </span>  </h1>
                </div>
            </div>
            <div className='container-fluid approach_container' >
                <div className='container' >
                    <div className='row' >
                        <div className='col d-flex justify-content-center approach_text' >
                            <h5> HOW TO APPROACH US FOR ONLINE CONSULTATION</h5>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col col-xl-8 approach_text'>
                            <ul>
                                <li> <strong>✦</strong> Send your reports through WHATSAPP to 9515621818 </li>
                                <li><strong>✦</strong> Book your online consultation</li>
                                <li><strong>✦</strong> Pay Consultation Fee: Rs.500/-</li>
                                <li><strong>✦</strong> Receive a call from our Experienced doctors</li>
                                <li><strong>✦</strong> Collect your prescription.</li>
                                <li><strong>✦</strong> Select your medicines from the list</li>
                                <li><strong>✦</strong> Book online and collect the medicines</li>
                                <li><strong>✦</strong> Courier /shipping charges will be applicable separately.</li>
                                <li><strong>✦</strong> Go to treatment at Your nearest center</li>
                                <li><strong>✦</strong> Collect bills and claim your insurance</li>
                            </ul>
                        </div>
                        <div className='col col-xl-4  d-flex justify-content-center '>
                            <div>
                                <Card className='approach_card'>
                                    <Card.Header className="approach_card_title" > <img className="home-contact-img" src="/images/icons/email.png" alt="Loading.." /> Email & Phone </Card.Header>
                                    <Card.Body>
                                        <Card.Title className="add_card_title_one approach_card_title_one" > kalpataru6282@gmail.com   </Card.Title>
                                        <Card.Text className="add_card_title_one approach_card_title_one"> +919515621818 </Card.Text>
                                    </Card.Body>
                                </Card>
                                <Card className='mt-3 approach_card'>
                                    <Card.Header className="approach_card_title" > <img className="home-contact-img" src="/images/icons/home.png" alt="Loading.." /> Head Office : </Card.Header>
                                    <Card.Body>
                                        <Card.Text className="add_card_text approach_card_title_one"> 15-143 New Mirjalaguda beside Tulasi Mitai Bandar Lane Malkajgiri Hyderabad 500047 </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApprochUs