import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import DiagnosisOne from '../Container/DiagnosisContainer/DiagnosisOne'
import DiagnosisTwo from '../Container/DiagnosisContainer/DiagnosisTwo'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'

function IRIDiagnosis() {
    return (
        <React.Fragment>
            <SEO title="Kalpataru || IRI Diagnosis" />
            <TopNav />
            <Breadcrumb image="/images/breadcrumb/iris.jpeg" title="Home" content="IRI Diagnosis" />
            <DiagnosisOne/>
            <DiagnosisTwo/>
            <Footer />
        </React.Fragment>
    )
}

export default IRIDiagnosis