import React from 'react'

function ShishuSwarnaTwo() {
    return (
        <div className='container' >
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        This is a land where the great warriors, scholars, and virtuous ones are born, but who stops the future champions from being born? India is a source of knowledge but why can't we create a remarkable future generation? Why is it that the children who were born can access limited knowledge but not reach out to the unconditional experience?
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        When will this land have the footsteps of great leaders? Today's world where we live is a result of modernization. But how long can it sustain our growth? Is there something where we need to learn? What is the solution?
                    </p>
                </div>
            </div>

            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        500 years before we acquired freedom, there were women leaders and great freedom fighters fighting for our nation. There were a lot of people who surrendered their lives for the love towards the nation. Some people has followed and protected Vedic culture. After MAHARSHI DAYANAND SARASWATI, there were no such legendary being born.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        India is land of adversities where mixed cultures and traditions were there. Hinduism had been corrupted by divergence from the founding principles of the Vedas and that Hindus had been misled by the priesthood for the priests' self-aggrandizement.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        For this mission, he founded the ARYASAMAJ,a socio-cultural organization. His ideologies also resonated with the spirit of India's freedom struggle.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        DAYANAND SARASWATI noticed that India and the Hindu society deplorable and depressing. The British ruled the country.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        Swami Dayanand resolved to awaken India and the Hindu society. He introduced many positive reforms, including the abolition of Sati Pratha, child marriage, dowry, untouchability in the prevalent caste system, and the introduction of women's education.
                    </p>
                </div>
            </div>

            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        He firmly believed that the yoke of the British Empire had to go. He emphasized nationhood by introducing SWARAJYA (SELF-RULE) for the first time and a need for one national language. In his famous book, SATYARTHAPRAKASH (The Light of Truth), he sought to dispel rituals, dogmas and superstitions among all Indians. This book is the essence of 4000 Vedic books. He paved a way to walk out of ignorance.
                        Shodasha sanskara vidhi vignanam is in built-in this sishu sanskara yagnam. It's a platform for all the parents who are aspiring for wonderful children with divine sanskaras.
                    </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> WHO CAN PARTICIPATE? </h2>
                    <p> Newly wedded couples, pregnant mothers, babies from 1 month to 19-year adults can attend this MAHAYAGNA. The above category can participate and can learn to acquire the right path </p>
                    <p> What would you learn in this yagna?  </p>
                    <p> Though every parent want their children to have a bright future, we are highly influenced by modern society. So the young minds are being exposed to present circumstances, preventing them from being on the right track.  </p>
                    <p>  We help in the child's upbringing like not getting affected by these modern customs. Though they are amidst in this world like water droplets on the lotus leaf they too would not be affected by society. Our 19 years if the relentless effort has paved the way to create this.  </p>
                    <p> Maharshi has thrown light on various concepts ranging from birth to death. Maharshi's contribution on 16 samskaras will also be given in this yagna. </p>
                    <p> Every couple should have an understanding on GARBADHANSANSKAR importance.
                        By attending this retreat, pregnant mothers will be equipped with food habits and awareness of daily routines to create a fantastic future for 9 months.
                    </p>
                    <p>This ritual enables you to acquire ancient wisdom, which will boost your confidence and inner stability and peace.  </p>
                </div>
            </div>


            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> What are the benefits of this Agnihotrahoma? </h2>
                    <p>
                        This homa creates a clean, nutritional and medicinal atmosphere and this atmosphere helps reduce physical/mental diseases. We use natural herbs and vanamulikas and Various materials are used in this therapy like cow dung cake, guduchi, rice, ghee and medicinal plants either as a firewood or Samidha.
                        Cow ghee from swadeshi cow is only used to perform homa.
                    </p>
                    <p>
                        Among SHODASHASANSKARA, 12 SISHUSANSKARA mantras were recited by Vedic scholars.
                        Ayurveda has advocated sterilization of water, air, soil, and atmosphere with Agnihotra's help, i.e., Homa, Yagya, Dhupana. Agnihotra is applied in Ayurveda for a therapeutic purpose is also known as Homa therapy.
                        Your children will acquire powerful blessings and inculcate good values by attending this sacred ritual.
                    </p>
                </div>
            </div>

            <div className='row' >
                <h3 className='about-two-title'> YAGNA DETAILS: </h3>
                <div className='col' >
                    <ul className='about-two-ul' >
                        <li> 1.	This program would be performed at every month on PUSHYAMI day. </li>
                        <li> 2.	From 7:00AM to 1:00PM.. </li>
                        <li> 3.	You will be seated according to assigned seats in the presence of vedicpandits. The homa would be performed.</li>
                        <li> 4.	Then homa would begin with sankalpa, ishwarastuthu, swasthi vachanam shanti prakarana,vedamantrochharana.</li>
                        <li> 5.	Then followed by Agniprajvalana with important mantras and offerings of viseshaahuti. </li>
                        <li> 6.	You can attend with your family and offer ahuti on the yagnavedika and get back to your own respective places. </li>
                        <li> 7.	Purnhuti and then yagnaharthi, shantimantra, yagna would be over between 9-9.30.  First Session 9.30 to 10.30 Breakfast time ,Again it will be restarted with presentation of 4 aspects by 10:30, and it would be over around by 1pm. </li>
                    </ul>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> WE HAVE A PRESENTATION OF 4 ASPECTS GIVEN BELOW FOR EVERY 45MINUTES. </h2>
                    <p> 1.	Importance of SHODASHASANSKARAS </p>
                    <p> 2.	Importance of child upbringing and the role of parents in preventing the effects of modern society on children </p>
                    <p> 3.	For a healthy lifestyle with the daily routine. </p>
                    <p> 4.	The help of our organization in the future for your families  </p>
                </div>
            </div>
            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'>Instructions to the participants: </h2>
                    <p> •	Participants can take a driver or assistant to assist the kids along with their families. </p>
                    <p> •	Parents and kids have to be in a white dress compulsory. </p>
                    <p> •	Participants are expected to arrive before 20 minutes and be seated on your allocated seats without causing any inconvience to the homa performed there. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'>Food Court - instructions </h2>
                    <p> •	Dependent on the time and amount paid, we provide milk, breakfast at 7am, and lunch at noon. </p>
                    <p> •	Homa is for 2hrs, so we provide cow milk and bournvita and corn flax to children to sustain. </p>
                    <p> •	We provide food between 7-9:30 in and during homa in case if the child is hungry. </p>
                    <p> •	Any emergency, our volunteers will reach out to you by cc camera help and attend to you. </p>
                    <p> •	After yagna, fruits and fruit juices would be provided before lunch.  </p>
                    <p> •	We see that we would not cause you any kind of inconvenience. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Participant instructions  </h2>
                    <p>
                        Take the help of volunteers at parking to reduce any kind of inconvenience to others or to yourself.
                        We provide you with REGD NO and tags and wear the tag and go inside.
                        Incase If you want to change your dress before homa we provide unique rooms.
                        We provide toilets at the backside of the vedika to reduce any kind of inconvenience to you.
                    </p>
                    <p>  Mokkai vonganidi manai vonguna?</p>
                    <p> Mokka=sapling;Manu= grown up tree</p>
                    <p> If not bent as a plant, would it bend when it grows into a tree?</p>
                    <p>
                        Comment: Children should be advised properly, when they are young, so that they do not stray into evil ways. This is an advice meant particularly for parents, who pamper children and allow them into undesirable ways
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ShishuSwarnaTwo