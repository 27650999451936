import React from 'react'

function HeartCareOne() {
    return (
        <div>
            <div className='section_heading'>
                <h1>Kalpataru <span>HeartCare</span> Treatment</h1>
            </div>

            <div className="container homeabout-main pt-5">

                <div className="row justify-content-between">

                    <div className=" col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 homeabout-col">
                        <p> ➤ More than 50% of the deaths and disability from heart disease and strokes, which together kill more than 12 million people each year, can be cut down by a combination of simple and cost effective national efforts and individual attentions to reduce major risk factors. 80% of all CVD deaths worldwide occur in developing, low and middle income countries. CVD has become one of the major leading causes of deaths in developing countries. It is estimated that 90% of CVD is preventable. Prevention of CVD involves improving risk factors through: healthy eating, exercise, avoidance of tobacco smoke and limiting alcohol intake.</p>
                        <p> ➤ According to Ayurveda, excessive consumption of food which is heavy to digest, dry, cold and with bitter or astringent qualities, excessive physical exertion, altered patterns of sleep including day sleep, forceful withholding of urges or initiation of urges, excessive indulgence in purgation, emesis or enema procedures, mental stress, excessive fear, over thinking, anxiety and indulgences in medications to counteract these things can be broadly classified into Aharaja, Viharaja and Manasika factors which in turn vitiate the agni and subsequently the rasadhatu. The impaired rasadhatu in circulation, in presence of impaired agni at the levels of koshta and dhatus and vitiated vyana vayu, eventually paves the way for metabolic disorders such as medoroga, sthoulya, prameha etc which are milestones in the pathogenesis of hrid-vikara.</p>

                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <img className="homeabout-image" src="https://www.narayanahealth.org/blog/wp-content/uploads/2018/06/shutterstock_564859810.jpg" height="390px" alt="Loading.." />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default HeartCareOne