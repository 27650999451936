import React from 'react'
import Progress from '../HomePageContainer/Progress'

export default function Abouthospital() {
  return (
    <div className="section_heading">
      <h1>Kalpataru <span> Ayurveda</span> Hospitals</h1>
      <div>
        <Progress />
      </div>
      <div className="container homeabout-main">
        <div className="row justify-content-between">
          <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12">
            <img src="/images/about/herb.jpg" className="homeabout-image " alt="..." />
          </div>
          <div className=" col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 homeabout-col">
            <p>Kalpataru Ayurveda Hospitals, Hyderabad was established in the year 2000 and successfully running till-date under the efficient supervision of World Vedic Health Sciences Private Limited, an organization that serves the finest Ayurveda Yoga, Natural Cure using traditional Medical care to patients. The hospital systems are based on cutting-edge technologies to assure ancient health care services to its beneficiaries with modulated systems, the hospital provides a wide range of Indian medicine and non-surgical and therapeutic care to patients of all age groups.</p>
            {/* <p>Due to the modernization and inclination towards western culture, India is losing its ancient heritage, Vedic culture and traditional educational system. Even after six decades of glorious independence India is still under the influence of slavery and bondage. Illiteracy and unemployment are the major factors affecting the global Indian economy.</p> */}
            {/* <p>Due to the modernization and inclination towards western culture, India is losing its ancient heritage, Vedic culture and traditional educational system. Even after six decades of glorious independence India is still under the influence of slavery and bondage. Illiteracy and unemployment are the major factors affecting the global Indian economy.</p> */}
            {/* <Link to="" className="about-link" >Know More</Link> */}
          </div>

        </div>
      </div>

    </div>


  )
}
