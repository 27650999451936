import React from 'react'

function CancerOne() {
    return (
        <div>
            <div className='section_heading'>
                <h1>Kalpataru <span>Cancer Care</span> Treatment</h1>
            </div>

            <div className="container homeabout-main pt-5">

                <div className="row justify-content-between">

                    <div className=" col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 homeabout-col">
                        <p> <b> Ayurveda as a Supportive Treatment to Cancer </b> </p>
                        <p> ➤ <b>CANCER:</b> Arbuda is the Sanskrit word for Tumor. According to Sushrut, the three Doshas (Vata, Pitta, and Kapha) when aggravated may develop into a malignant tumor especially the Kapha and Vata Doshas. This affects the tissue, which will result in developing into a malignant tumor.</p>
                        <h5 className='app-heading'>The above said tumor will have the following characteristics:-</h5>
                        <ul className='treatment-ortho-ul'>
                            <li> Round, Firm, Swollen, fleshy growth  </li>
                            <li> Causes mild pain, Large  </li>
                            <li> Deeply rooted in the body, Passive growth  </li>
                            <li> Shows no Suppuration (i.e. pus discharge)  </li>
                        </ul>

                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <img className="homeabout-image" src="https://img.freepik.com/free-photo/female-patient-doctor-talking-nice-subject_23-2149017309.jpg?w=996&t=st=1671471631~exp=1671472231~hmac=d52293e0cc99003a4da7574eecd9b7262dcfb61ecc3a1c5c5c74d30d33a04610" height="290px" alt="Loading.." />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CancerOne