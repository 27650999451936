import React from 'react'
import Footer from '../Container/Footer/Footer'
import ApprochUs from '../Container/HomePageContainer/ApprochUs'
import HomeAbout from '../Container/HomePageContainer/HomeAbout'
import HomeContact from '../Container/HomePageContainer/HomeContact'
import HomeExplore from '../Container/HomePageContainer/HomeExplore'
import HomeFacilities from '../Container/HomePageContainer/HomeFacilities'
import HomeOurLocation from '../Container/HomePageContainer/HomeOurLocation'
// import HomeOurProducts from '../Container/HomePageContainer/HomeOurProducts'
import HomeService from '../Container/HomePageContainer/HomeService'
import Progress from '../Container/HomePageContainer/Progress'
import Slider from '../Container/HomePageContainer/Slider'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'

const Home = () => {
    return (
        <React.Fragment>
            <SEO title="Kalpataru || Home" />
            <TopNav />
            <Slider />
            <Progress />
            <HomeAbout />
            <HomeExplore />
            <HomeService />
            <ApprochUs />
            <HomeFacilities />
            {/*    <HomeOurProducts/> */}
            <HomeOurLocation />
            <HomeContact />
            <Footer />
        </React.Fragment>
    )
}

export default Home
