import React from 'react'

function GalleryTwo() {
    return (
        <div class="container pt-5">
            <div class="row">
                <div className='row mt-2' >
                    <div className='col text-center mb-3 mt-2' >
                        <h4>CHRONIC HEALTH ISSUES –SOLUTION AT KALPATARU </h4>
                        <a href='https://www.youtube.com/watch?v=G2tyyp_joV4&t=69s' target="_blank" >  https://www.youtube.com/watch?v=G2tyyp_joV4&t=69s </a> <div></div>
                        <a href='https://www.youtube.com/watch?v=3IBNZFB8naI&t=328s' target="_blank" >  https://www.youtube.com/watch?v=3IBNZFB8naI&t=328s </a> <div></div>
                        <a href='https://www.youtube.com/watch?v=yawkks9YAtI&t=21s' target="_blank" >  https://www.youtube.com/watch?v=yawkks9YAtI&t=21s </a> <div></div>
                        <a href='https://www.youtube.com/watch?v=4P-eJ-nuyV4&t=4s' target="_blank" >  https://www.youtube.com/watch?v=4P-eJ-nuyV4&t=4s </a> <div></div>
                        <a href='https://www.youtube.com/watch?v=rr1ofgUO8BY&t=40s' target="_blank" >  https://www.youtube.com/watch?v=rr1ofgUO8BY&t=40s </a> <div></div>
                    </div>
                </div>
                <div className='row mt-2' >
                    <div className='col text-center mb-3 mt-2' >
                        <h4>SPECIAL TREATMENTS –SUCCESS RESULTS  </h4>
                        <a href='https://www.youtube.com/watch?v=0jYzVEJkq5U' target="_blank" >   https://www.youtube.com/watch?v=0jYzVEJkq5U </a> <div></div>
                        <a href='https://www.youtube.com/watch?v=_XRPyrCFJqY' target="_blank" >   https://www.youtube.com/watch?v=_XRPyrCFJqY </a> <div></div>
                        <a href=' https://www.youtube.com/watch?v=hosR5j40jK0&t=65s' target="_blank" >   https://www.youtube.com/watch?v=hosR5j40jK0&t=65s </a> <div></div>
                        <a href='https://www.youtube.com/watch?v=qbc14cVFlHo' target="_blank" >  https://www.youtube.com/watch?v=qbc14cVFlHo </a> <div></div>
                        <a href='https://www.youtube.com/watch?v=LIeTwzZZrMs&t=135s' target="_blank" >  https://www.youtube.com/watch?v=LIeTwzZZrMs&t=135s </a> <div></div>
                        <a href='https://www.youtube.com/watch?v=EeixafdISg8&t=12s' target="_blank" >  https://www.youtube.com/watch?v=EeixafdISg8&t=12s </a> <div></div>
                    </div>
                </div>
                <div className='row mt-2' >
                    <div className='col text-center mb-3 mt-2' >
                        <h4>SWARNABINDU PRASHANAM (for child immunity) </h4>
                        <a href='https://www.youtube.com/watch?v=H2n2AX02zO8&t=13s' target="_blank" >      https://www.youtube.com/watch?v=H2n2AX02zO8&t=13s </a> <div></div>
                        <a href='https://www.youtube.com/watch?v=caSu58HmPU4' target="_blank" >        https://www.youtube.com/watch?v=caSu58HmPU4 </a> <div></div>
                    </div>
                </div>
                <div className='row mt-2' >
                    <div className='col text-center mb-3 mt-2' >
                        <h4>IRIDIAGNOSIS (watch very important videos )  </h4>
                        <a href='https://www.youtube.com/watch?v=5jaA3TNPMCo&t=1188s' target="_blank" >   https://www.youtube.com/watch?v=5jaA3TNPMCo&t=1188s </a> <div></div>
                        <a href='https://www.youtube.com/watch?v=7RRtqENdRoE&t=65s' target="_blank" >     https://www.youtube.com/watch?v=7RRtqENdRoE&t=65s </a> <div></div>
                        <a href='https://www.youtube.com/watch?v=hTX7GA79s3c&t=58s' target="_blank" >      https://www.youtube.com/watch?v=hTX7GA79s3c&t=58s </a> <div></div>
                        <a href='https://www.youtube.com/watch?v=_0b7fLBEvto&t=375s' target="_blank" >     https://www.youtube.com/watch?v=_0b7fLBEvto&t=375s </a> <div></div>

                    </div>
                </div>
                <div className='row mt-2' >
                    <div className='col text-center mb-3 mt-2' >
                        <h4>KRIYA YOGA SAMSTAN YOGA SYLLABUS (Regular Course) </h4>
                        <a href='https://www.youtube.com/watch?v=aK8bkwc87eM&t=15s' target="_blank" >     https://www.youtube.com/watch?v=aK8bkwc87eM&t=15s </a> <div></div>
                        <a href='https://www.youtube.com/watch?v=Kw0XxFCF7P0&t=6s' target="_blank" >    https://www.youtube.com/watch?v=Kw0XxFCF7P0&t=6s </a> <div></div>
                        <a href='https://www.youtube.com/watch?v=1x1Uapn6eDc&t=27s' target="_blank" >     https://www.youtube.com/watch?v=1x1Uapn6eDc&t=27s </a> <div></div>
                        <a href=' https://www.youtube.com/watch?v=VyPsE8HHyIU&t=3s' target="_blank" >    https://www.youtube.com/watch?v=VyPsE8HHyIU&t=3s </a> <div></div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default GalleryTwo