import React from 'react'
import { ListGroup } from 'react-bootstrap'

function FranchiseTwo() {
    return (
        <div className='container-fluid'>

            <div className='row' >
                <div className='col' >
                    <div className='row' >
                        <div className='col franchise_title mb-3 mt-3' > Join Us to become a Franchise! </div>
                    </div>
                    <div className='row'>
                        <div className=' col franchise_title_two'>
                            We are looking for franchises in PAN-INDIA
                        </div>
                    </div>
                    <div className='row mt-3 '>
                        <div className='col'>
                            <ListGroup.Item as="li">   <b> Category : </b> <span> Ayurveda Clinics / Nature Cure Clinics / Hospitals </span> </ListGroup.Item>
                            <ListGroup.Item as="li">   <b> Sector : </b> <span> Hospitals, Clinics, and Medical stores </span> </ListGroup.Item>
                            <ListGroup.Item as="li">   <b> Service Area : </b> <span> Spine And Neuro Care –Surgery Stage </span> </ListGroup.Item>
                            <ListGroup.Item as="li">  <b> Concept : </b> <span> Ayurveda Spine Care & Neuro Care </span> </ListGroup.Item>
                            <ListGroup.Item as="li">   <b> Agreement Period : </b> <span> The Agreement tenure is 5 years. </span> </ListGroup.Item>
                            <ListGroup.Item as="li">  <b> Total No. of Units : </b> <span> 100 Units </span></ListGroup.Item>
                            <ListGroup.Item as="li"> <b> Total No. of Units we have established till now : </b> <span> 20 Units </span> </ListGroup.Item>
                            <ListGroup.Item as="li">  <b> Support Offered from our end : </b> <span> We provide training and Marketing Support to our Franchisees. </span> </ListGroup.Item>
                            <ListGroup.Item as="li">   <b> Investment required : </b> <span> 30 Lac – 50 Lac </span> </ListGroup.Item>
                            <ListGroup.Item as="li">   <b> Franchise Only for : </b> <span> Pure Vegetarians </span> </ListGroup.Item>
                            <ListGroup.Item as="li">  <b> Business Established : </b> <span> 1999 </span> </ListGroup.Item>
                            <ListGroup.Item as="li">   <b> Franchising Commenced : </b> <span>2011 </span> </ListGroup.Item>
                        </div>
                    </div>
                </div>
                <div className='col' >
                    <div className='row' >
                        <div className='col franchise_title mb-3 mt-3' > Collaborate with Us! </div>
                    </div>
                    <div className='row'>
                        <div className=' col franchise_title_two'>
                            We are looking for PAN-INDIA Collaborations
                        </div>
                    </div>

                    <div className='row mt-3 ' >
                        <div className='col '>
                            <ListGroup.Item as="li">    <b> Category : </b>  <span> All India Ayurveda and Nature Cure Hospitals </span>  </ListGroup.Item>
                            <ListGroup.Item as="li">    <b> Service Area : </b>  <span>  Ayurveda Spine And Neuro care  </span></ListGroup.Item>
                            <ListGroup.Item as="li">    <b> Investment : </b>  <span> Nill </span> </ListGroup.Item>
                            <ListGroup.Item as="li">    <b> Requirement : </b>  <span> Well Established Hospital Or Clinics </span> </ListGroup.Item>
                            <ListGroup.Item as="li">    <b> Hospital Size : </b>  <span> We require at least a well-equipped 10-Bedded facility for Inpatients </span> </ListGroup.Item>
                            <ListGroup.Item as="li">    <b> Collaboration Agreement : </b>  <span> At least 3-5 Years  (FREE /PAID By Terms and conditions)  </span></ListGroup.Item>
                            <ListGroup.Item as="li">    <b>  Publicity : </b>   <span>Electronic, Print, Google, Social Media, and local Publicity </span> </ListGroup.Item>
                            <ListGroup.Item as="li">    <b> Income Sharing :</b>   <span> According to Agreement's Terms and conditions </span> </ListGroup.Item>
                            <ListGroup.Item as="li">    <b> Support: </b>  <span> We will offer 100% support with Training  </span></ListGroup.Item>
                            <ListGroup.Item as="li">    <b> Quality : </b>  <span>  We do not compromise on quality </span> </ListGroup.Item>
                        </div>
                    </div>

                </div>

            </div>
            <div className='container' >
                <div className='row mt-4' >
                    <div className='col' >
                        <p> <b> ( We anticipate Franchisees who have good business skills, and management skills and are service-oriented ) </b> </p>
                    </div>
                </div>
                <div className='row' >
                    <div className='col expo_col' >
                        <h2 className='expo_one_title'> IRIDIAGNOSIS FRANCHISE  </h2>
                        <p> I would like to take this opportunity to introduce Kriya Yoga Samsthan Kalpataru Ayurveda Hospitals.  IRIS analysis test center provides franchises to your places, For your understanding, This test can tell the Past, Present, and Future, Genetic diseases’ to patients. It can help many patients to identify their diseases before they are getting them, so for that Kalpataru Health care has done much research to bring this test to Andhra Pradesh/ Telangana Districts. Now we are giving franchises to both states who are interested. We are providing many facilities and the flexibility to take this franchise to your locations all over India.  </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FranchiseTwo