import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'
import Diabets1 from '../Container/Diabetstreatment/Diabets1'
import Diabets2 from '../Container/Diabetstreatment/Diabets2'
import Diabets3 from '../Container/Diabetstreatment/Diabets3'

export default function Diabets() {
  return (

    <React.Fragment>
      <SEO title="Kalpataru || Diabetes" />
      <TopNav />
      <Breadcrumb image="/images/breadcrumb/diabetes.jpg" title="Home" content="Diabetes" />
      <Diabets1 />
      <Diabets2 />
      <Diabets3 />
      <Footer />
    </React.Fragment>


  )
}
