import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'
import CancerOne from '../Container/CancerContainer/CancerOne'
import CancerTwo from '../Container/CancerContainer/CancerTwo'
import CancerThree from '../Container/CancerContainer/CancerThree'

export default function CancerCare() {
  return (
    <React.Fragment>
      <SEO title="Kalpataru || CancerCare" />
      <TopNav />
      <Breadcrumb image="/images/breadcrumb/CancerCare.jpg" title="Home" content="CancerCare" />
      <CancerOne />
      <CancerTwo />
      <CancerThree />
      <Footer />
    </React.Fragment>
  )
}
