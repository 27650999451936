import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import ProductOne from '../Container/BuyProductContainer/ProductOne'
import RegisterForm from '../Container/Register/RegisterForm'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'

export default function BuyProducts() {
    return (
        <React.Fragment>
            <SEO title="Kalpataru || Register" />
            <TopNav />
            <Breadcrumb image="images/breadcrumb/register.jpg" title="Home" content="Register" />
            {/*  <ProductOne/> */}
            <RegisterForm />
            <Footer />
        </React.Fragment>
    )
}
