import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'
import Obesity1 from '../Container/Obesitytreatment/Obesity1'
import Obesity2 from '../Container/Obesitytreatment/Obesity2'
import Obesity3 from '../Container/Obesitytreatment/Obesity3'

export default function Obesity() {
  return (

    <React.Fragment>
      <SEO title="Kalpataru || Obesity" />
      <TopNav />
      <Breadcrumb image="/images/breadcrumb/obesity.jpg" title="Home" content="Obesity" />
      <Obesity1 />
      <Obesity2 />
      <Obesity3 />
      <Footer />
    </React.Fragment>


  )
}
