import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'
import HeartCareOne from '../Container/HeartCareContainer/HeartCareOne'
import HeartCareTwo from '../Container/HeartCareContainer/HeartCareTwo'
import HeartCareThree from '../Container/HeartCareContainer/HeartCareThree'

export default function HeartCare() {
  return (
    <React.Fragment>
      <SEO title="Kalpataru || HeartCare" />
      <TopNav />
      <Breadcrumb image="/images/breadcrumb/HeartCare.jpg" title="Home" content="HeartCare" />
      <HeartCareOne />
      <HeartCareTwo />
      <HeartCareThree />
      <Footer />
    </React.Fragment>
  )
}
