import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row, Button, InputGroup } from 'react-bootstrap'
import RegisterValidation from './RegisterValidation';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';

export default function HomeContact() {

    const history = useHistory();
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [values, setValues] = useState({
        fullName: "",
        email: "",
        mobile: "",
        location: "",
        comment: "",
        appointmentDate: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value, });
        setErrors(RegisterValidation(values));
    };

    const handleSubmit = () => {

        let formData = new FormData();

        formData.append("FullName", values.fullName);
        formData.append("Email", values.email);
        formData.append("Mobile", values.mobile);
        formData.append("Location", values.location);
        formData.append("Comment", values.comment);
        formData.append("Appointment Date", values.appointmentDate);

        setLoading(true);
        const api = "https://www.actionforms.io/e/r/kalpataruappointment";

        Axios.post(api, formData)
            .then((response) => {
                console.log(response)
                if (response.status === 200) {
                    setLoading(false);
                    history.push("/success");
                } else {
                    console.log(response)
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log("err", err)
                setLoading(false);
            })
    };

    useEffect(() => {
        setErrors(RegisterValidation(values));
    }, [])

    const register = (e) => {
        e.preventDefault();
        handleSubmit();
    }

    return (
        <Container className="mt-5" >
            <Container className="home-contact-container" >
                <Row >
                    <Col xl={6} lg={6} md={12} sm={12} xs={12} >
                        <div>
                            <h3 className="home-contact-title"> Contact us </h3>
                            <h2 className="home-contact-title-one"> Get in touch with us!  </h2>
                        </div>
                        <div className="home-contact-list">
                            <ul>
                                <li>
                                    <img className="home-contact-img" src="images/icons/phone.png" alt="Loading.." />
                                    <a href="tel:+1608-235-2485" > +919515621818, +919515631818</a>
                                </li>
                                <li>
                                    <img className="home-contact-img" src="images/icons/email.png" alt="Loading.." />
                                    <a href="tel:+1608-235-2485" > kalpataru6282@gmail.com   </a>
                                </li>
                                <li>
                                    <img className="home-contact-img" src="/images/icons/home.png" alt="Loading.." />
                                    <a>15-143 New Mirjalaguda, Near Tulasi Mitai Bandar Malkajgiri Hyderabad Telangana -500047 </a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12} className="home-contact-form-col" >
                        <Row> <h3 className="home-contact-title-one text-center"> Book Appointment </h3> </Row>

                        <form className="row g-3 " onSubmit={register}>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                                <label for="fullName" className="form-label">Full Name</label>
                                <input type="text"
                                    className="form-control"
                                    id="fullName"
                                    placeholder="Enter Full Name"
                                    name="fullName"
                                    value={values.fullName}
                                    onChange={handleChange}
                                    required
                                />
                                {
                                    errors.name ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.name} </div> : null
                                }
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                                <label for="email" className="form-label">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Enter Your Email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    autoComplete="off"
                                    required
                                />
                                {
                                    errors.email ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.email} </div> : null
                                }
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                                <label for="mobile" className="form-label">Mobile Number</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="mobile"
                                    placeholder="Enter Your Mobile"
                                    name="mobile"
                                    value={values.mobile}
                                    onChange={handleChange}
                                    autoComplete="off"
                                    required
                                />
                                {
                                    errors.mobile ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.mobile} </div> : null
                                }
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                                <label for="appointmentDate" className="form-label">Select Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="date"
                                    placeholder="Enter Date"
                                    name="appointmentDate"
                                    value={values.appointmentDate}
                                    onChange={handleChange}
                                    required
                                />
                                {
                                    errors.appointmentDate ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.appointmentDate} </div> : null
                                }
                            </div>
                            <div className="col-xl-12">
                                <label for="location" className="form-label">Location</label>
                                <select id="location" className="form-select" name="location" value={values.location} onChange={handleChange} required>
                                    <option selected>Choose...</option>
                                    <option>Hyderabad</option>
                                    <option>Mumbai</option>
                                    <option>Banglore</option>
                                </select>
                                {
                                    errors.location ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.location} </div> : null
                                }
                            </div>

                            <div className="col-xl-12">
                                <label for="comment" className="form-label">Enter Comment </label>
                                <textarea
                                    type="textarea"
                                    className="form-control"
                                    id="comment "
                                    placeholder="Enter comment "
                                    name="comment"
                                    value={values.comment}
                                    onChange={handleChange}
                                    required
                                />
                                {
                                    errors.comment ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.comment} </div> : null
                                }
                            </div>

                            <div className='row' >
                                <div className="col-xl-4 mt-3">
                                    <button type="submit" className="home-contact-btn ">
                                        {
                                            loading
                                                ?
                                                <div className="spinner-border spinner-border-sm text-light" style={{ marginRight: '5px' }} role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                                :
                                                " "
                                        }
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>

                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
