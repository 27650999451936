import React from 'react'

function CancerTwo() {
    return (

        <section className="Kalpataru-second-ortho pt-5">

            <div className="container-fluid">

                <div className="row mt-30">
                    <div className="col-md-3 col-sm-6">
                        <a href=""> <div className="ortho-card">
                            <img src="/images/CancerCare/CancerCare1.png" style={{ "height": "180px" }} alt="Image" />
                            <div className="ortho-content">
                                <p>CancerCare</p>
                            </div>

                        </div></a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href=""><div className="ortho-card">
                            <img src="/images/CancerCare/CancerCare2.jpg" style={{ "height": "180px" }} alt="Image" />
                            <div className="ortho-content">
                                <p>CancerCare</p>
                            </div>

                        </div></a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href=""><div className="ortho-card">
                            <img src="/images/CancerCare/CancerCare3.jpeg" style={{ "height": "180px" }} alt="Image" />
                            <div className="ortho-content">
                                <p>CancerCare</p>
                            </div>

                        </div></a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href=""><div className="ortho-card">
                            <img src="/images/CancerCare/CancerCare4.jFIF" style={{ "height": "180px" }} alt="Image" />
                            <div className="ortho-content">
                                <p>CancerCare</p>
                            </div>

                        </div></a>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default CancerTwo