import React from 'react'

function CoursesOne() {
    return (
        <div className='container mb-3' >
            <div className="section_heading">
                <h1>Register For <span> Courses </span> </h1>
            </div>
            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> ADMISSIONS: </h2>
                    <p> <b> Ayurveda ( Bams Md ) -  Naturopathy( Bnys) -Yoga (Dip/Bsc.Msc Ph.D.)  Chiropractic Therapy  Iridiagnosis (International Standards).</b>  </p>
                    <p>  Kalpataru ‘Ayurveda Hospitals' main intention is to develop our traditional Treatment Methods In India.  For this cause, we motivate our students to study the best medical syllabus i.e., Ayurveda and yoga, and nature cure Courses, and they are aware that these  courses are equal to MBBS studies. MBBS and BAMS BNYS syllabi are the same but treatment methods are different. The approaches are different. We offer support to students who are weak in their studies through private classes, Anatomy, Physiology, and Biochemistry Classes both online and offline.</p>
                    <p>   We offer extensive support to those who are completed PU with science-based subjects. We plan to provide admissions throughout India. We support our students even after the course and we train them in a  good system of treatment, Management Commercial and Hospitality Etc</p>
                </div>
            </div>
            <div className='row mt-2' >
                <div className='col text-center mb-3 mt-2' >
                    <h4>1. BAMS /BNYS Course Duration is 4.5 years and 1-year Internship </h4>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Eligibility: </h2>
                    <p> PU Science/NEET </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Future life:  </h2>
                    <p> The Best doctors for serving people and 100 % satisfied. 100%  of jobs will be govt and the private sector. They are assured to get a good income source and good position and also talented persons to do any medical research etc. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> BNYS (Bachelor of Naturopathy and Yogic Science.): </h2>
                    <p> BNYS is a 5.5-year undergraduate degree focussing on naturopathy and the use of yoga in treating bodily ailments. Students must complete 1 year of mandatory internship after completing the 4.5 Year UG course. </p>
                </div>
            </div>
            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> BAMS  (Bachelor of Ayurvedic Medicine and Surgery.): </h2>
                    <p> BAMS is an undergraduate degree program designed to make students familiar with the concepts of Ayurveda and use them for the treatment of patients. BAMS incorporates Ayurveda with the ideas of modern medicines, and students are taught a combination of both as part of the course curriculum. </p>
                </div>
            </div>
            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> YOUTH EMPLOYMENT COURSES: </h2>
                    <p> AspiringYouth is the backbone of the nation. Unfortunately, some of them are turning addicted to drugs, spoiling their intelligence, and debilitating their youthful period, and the nation. Their addiction to drugs is leading to their rebellious behavior, thereby causing large-scale arson, and violence disturbing the peace in society, and spoiling their lives. The reason is, the drugs that were consumed disturb the cells in the brain. To bring a radical change in the youth, we formed divisions that deal with the youth employability solutions and traditional business solutions, and make a concentrated effort to change their mindset, and travel in a developmental path.</p>
                </div>
            </div>

            <div className='row' >
                <div className='col-xl-6 mr-2' >
                    <img className='homeabout-image' src='/images/other/course.jpeg' alt="Loading.." />
                </div>
                <div className='col-xl-6 ml-2 mt-2' >
                    <img className='homeabout-image' src='/images/other/course1.jpeg' alt="Loading.." />
                </div>
            </div>

            <div className='row mt-4' >
                <div className='col text-center mb-3 mt-2' >
                    <h4>YOGA TEACHER TRAINING COURSE 1 </h4>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Eligibility: </h2>
                    <p> +2 and any degree with science-based subjects </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Course Duration: </h2>
                    <p> 3 Months Regular course, Trainees will attend the classes regularly both theory and practical. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Subjects and Topics: </h2>
                    <p> Yoga Syllabus, Basic Yoga Courses Level 1-3, Pranayama meditation.   </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Career: </h2>
                    <p> You can become a good Yoga Teacher /Establish your Own Yoga Centre. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Income Source: </h2>
                    <p> Honest Income; You will earn a good name in society and people will trust you based on your talent, and efficiency. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Our Support: </h2>
                    <p> We will offer 100% support. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Training Locations: </h2>
                    <p> Hyderabad | Bangalore | Mumbai. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Certificate: </h2>
                    <p> We will provide Valid training certificates from our Institution. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Fee Details: </h2>
                    <p> Rs.16500/- </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Jobs: </h2>
                    <p> Our Institution will provide direct appointments after the training and also we send trainees to other hospitals, Fitness Centers, and Corporate Institutions based on the requirements. </p>
                </div>
            </div>


            <div className='row mt-4' >
                <div className='col text-center mb-3 mt-2' >
                    <h4>YOGA TEACHER TRAINING COURSE 2 </h4>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Eligibility: </h2>
                    <p> +2 and any degree with science-based subjects. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Course Duration: </h2>
                    <p> 9 Months Regular course. Trainees will attend the classes regularly both theory and practical. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Subjects and Topics: </h2>
                    <p> Yoga Syllabus Advanced 1-5 Level, Patanjali Yoga Sutras, Human Anatomy Physiology Basics Pranayama Bandha Mudra And Vedic Philosophy.  </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Future: </h2>
                    <p> You will become a Professional Yoga Teacher And Yoga Therapist. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Income Source: </h2>
                    <p>  Honest Income; You will earn a good name in society and people will trust you based on your talent, and efficiency. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Our Support: </h2>
                    <p> We will offer 100% support. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Training Locations: </h2>
                    <p> Hyderabad | Bangalore | Mumbai. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Certificate: </h2>
                    <p> We will provide Valid training certificates from our Institution. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Fee Details: </h2>
                    <p> Rs. 45000/- </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Jobs: </h2>
                    <p> Our Institution will provide direct appointments after the training and also we send trainees to other hospitals, Fitness Centers, and Corporate Institutions based on the requirements. </p>
                </div>
            </div>

            <div className='row mt-4' >
                <div className='col text-center mb-3 mt-2' >
                    <h4>AYURVEDA PANCHA KARMA THERAPY </h4>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Eligibility: </h2>
                    <p> +2 and any degree with science-based subjects. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Course Duration: </h2>
                    <p> 1-3 Months Regular course. Trainees will attend the classes regularly both theory and practical. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Subjects and Topics: </h2>
                    <p> Basic Knowledge of Human Anatomy, Ayurveda, and panchakarma treatments and procedures.  </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Future: </h2>
                    <p>Experienced Ayurveda Panchakarma Therapists have a bright future for a lifetime. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Income Source: </h2>
                    <p>  Honest Income; You will earn a good name in society and people will trust you based on your talent and efficiency. The basic salary is Rs. 15000/- in India. Experienced can get a salary up to Rs. 30000 In India and in abroad, you can get up to Rs. 80000/- and above in Indian currency. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Our Support: </h2>
                    <p> We will offer 100% support. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Training Locations: </h2>
                    <p> Hyderabad | Bangalore | Mumbai. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Certificate: </h2>
                    <p> We will provide Valid training certificates from our Institution. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Fee Details: </h2>
                    <p> Rs.16500/- to Rs. 45000/- </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Course Duration: </h2>
                    <p> 9 Months </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Jobs: </h2>
                    <p> Our Institution will provide direct appointments after the training and also we send trainees to other hospitals, Fitness Centers, and Corporate Institutions based on the requirements. </p>
                </div>
            </div>

            <div className='row mt-4' >
                <div className='col text-center mb-3 mt-2' >
                    <h4>CHIROPRACTIC THERAPY</h4>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Eligibility: </h2>
                    <p> +2 and any degree with science-based subjects/ Doctors and Physiotherapists. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Course Duration: </h2>
                    <p> 1 Month Regular course. Trainees will attend the classes regularly both theory and practical. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Subjects and Topics: </h2>
                    <p> Basic Human Anatomy, Physiology & Chiropractic Syllabus etc.  </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Future: </h2>
                    <p>You will become a good Chiropractic Physician. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Income Source: </h2>
                    <p> Honest Income; You will earn a good name in society and people will trust you based on your talent and efficiency. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Our Support: </h2>
                    <p> We will offer 100% support. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Training Locations: </h2>
                    <p> Hyderabad | Bangalore | Mumbai. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Certificate: </h2>
                    <p> We will provide Valid training certificates from our Institution. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Fee Details: </h2>
                    <p> Rs. 45000 /- </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Jobs: </h2>
                    <p> Our Institution will provide direct appointments after the training and also we send trainees to other hospitals, Fitness Centers, and Corporate Institutions based on the requirements. </p>
                </div>
            </div>

            <div className='row mt-4' >
                <div className='col text-center mb-3 mt-2' >
                    <h4>DIPLOMA IN IRIDIAGNOSIS TECHNICIAN (INDIA/UK)</h4>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Eligibility: </h2>
                    <p> +2 and any degree with science-based subjects. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Course Duration: </h2>
                    <p> 9 Months Regular course. Trainees will attend the classes regularly both theory and practical. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Subjects and Topics: </h2>
                    <p> Iris Diagnosis, Diagnosis methods, Basic Anatomy, Physiology, and Biochemistry Subjects, Lab techniques, etc.  </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Future: </h2>
                    <p>Iris diagnosis is an occupation that is in demand in India and abroad and provides a good income source and professional equal to other lab technicians. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Income Source: </h2>
                    <p> Honest Income; You will earn a good name in society and people will trust you based on your talent and efficiency. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Our Support: </h2>
                    <p> We will offer 100% support. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Training Locations: </h2>
                    <p> Hyderabad | Bangalore | MumbaiHyderabad | Bangalore | Mumbai | Delhi | Punjab </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Certificate: </h2>
                    <p> We will provide Valid International Certificate from the UK. </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Fee Details: </h2>
                    <p> Rs.65000/- Including book materials   </p>
                </div>
            </div>

            <div className='row' >
                <div className='col expo_col' >
                    <h2 className='expo_one_title'> Jobs: </h2>
                    <p> Our Institution will provide direct appointments after the training and also we send trainees to other hospitals in India and Abroad. </p>
                </div>
            </div>




        </div>
    )
}

export default CoursesOne