import React from 'react'

function InfertilityThree() {
    return (
        <div>
            <div class="container pt-5">
                <div class="row">
                    <div class="col-md-8">
                        <div className='orthocare-desc'>
                            <p className="para-text">  ➤ Infertility is a significant clinical problem today, affecting 8-12% of couples worldwide. A minimum of 80 million pairs suffer from involuntary  infertility  worldwide,  secondary  infertility (having  had  a  previous  pregnancy)  rates  were  doublethe  primary  infertility  rates,  whereas  primary  infertility (no  previous  pregnancy)  rate  range  from  3  to  over 30%. The main disorders involved in infertility include pathological spermiogram, ovulation problems/ an ovulation, tubal diseases, pelvic adhesion/endometriosis,  cervical  factors,  and  idiopathic  reason usually    qualified    as    the    so-called unexplained infertility.</p>
                            <p className="para-text"> ➤ Beej stands for healthy ovum and sperm.</p>
                            <p className="para-text"> ➤ Ayurveda  on  the  other  hand  looks deeply into the individual  human constitution and   aims   to   enhance the functioning of body systems that participate  in  the  process  of  fertilization  in  total. It is clear that a systematic approach with Ayurvedic principles is effective in managing infertility as an effective, natural, safe, and cost-effective method.</p>

                            <div className='row mt-2' >
                                <div className='col text-center mb-3 mt-2' >
                                    <h4>Treatment Under Supervision Of  Dr. SRIMNANARAYANA, Ph.D.  B.A.M.S. | M.Sc., M.D. (Ayu) Rasashastra | Ph.D. (Ayu) Rasashastra </h4>
                                </div>
                            </div>

                            <h5 class=" app-heading">CONTRIBUTIONS:-</h5>
                            <p className="para-text">  ➤ For the past 38 years, he has been actively performing conclusive diagnosis with modern tools and applications for various chronic and acute diseases such as CARDIAC, CARCINOMA, Leukemia, Respiratory troubles, ACUTE AND CHRONIC RENAL FAILURE, Liver disorders, Auto-immune diseases such as Psoriasis, Rheumatoid Arthritis, Gout, Gallstones, Renal calculi, Metabolic disorders like Diabetes Mellitus, Thyroidism, Pediatric, and Gynecological disorders, Impotence, Frigidity, INFERTILITY, and other Psychological Ailments.</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-6'>
                                <div className="ortho-left-side treat_side_card">
                                    <h4>Make An appointment </h4>
                                    <ul className='treat_ul'>
                                        <li> Monday-Friday <span> : 9:00 AM - 17.00 PM </span></li>
                                        <li>Saturday<span style={{ marginLeft: '42px' }}> : 9:00 AM - 16:00 PM</span></li>
                                        <li>Sunday <span style={{ marginLeft: '51px' }}> : Closed</span></li>
                                        <b> <li>Need Urgent Help ? <span>+919515621818</span></li></b>
                                    </ul>
                                    <div className="appointment-btn pt-10">
                                        <span></span>
                                        <a href="/register" className="main-btn treat_btn ">Enroll Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-6 pt-3'>
                                <div className='ortho-card-secondpart '>

                                    <div className='ortho-image-part'>
                                        <div className='image'>
                                            <img src="/images/Infertility/Infertility5.jpg" style={{ "height": "180px", "width": "293px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Infertility</h4>
                                        </div>
                                    </div><br />
                                    <div className='ortho-image-part '>
                                        <div className='image'>:
                                            <img src="/images/Infertility/Infertility6.jpg" style={{ "height": "180px", "width": "290px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Infertility </h4>
                                        </div>
                                    </div><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    )
}

export default InfertilityThree