import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'
import PailsOne from '../Container/PailsContainer/PailsOne'
import PailsTwo from '../Container/PailsContainer/PailsTwo'
import PailsThree from '../Container/PailsContainer/PailsThree'

export default function Pails() {
  return (
    <React.Fragment>
      <SEO title="Kalpataru || Piles" />
      <TopNav />
      <Breadcrumb image="/images/breadcrumb/Piles.jpg" title="Home" content="Piles" />
      <PailsOne />
      <PailsTwo />
      <PailsThree />
      <Footer />
    </React.Fragment>


  )
}
