import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'
import Neck1 from '../Container/Neckpaintretment/Neck1'
import Neck2 from '../Container/Neckpaintretment/Neck2'
import Neck3 from '../Container/Neckpaintretment/Neck3'
import Neck4 from '../Container/Neckpaintretment/Neck4'

export default function Neckpain() {
  return (
    <React.Fragment>
      <SEO title="Kalpataru ||NeckPain" />
      <TopNav />
      <Breadcrumb image="/images/breadcrumb/neckpain.jpg" title="Home" content="NeckPain" />
      <Neck1 />
      <Neck2 />
      <Neck3 />
      <Neck4 />
      <Footer />
    </React.Fragment>


  )
}
