import React from 'react';
import { ArrowRight } from 'react-bootstrap-icons';

export default function Back4() {
    return (

        <div>
            <div class="container pt-5">
                <div class="row">
                    <div class="col-md-8">
                        <div className='orthocare-desc'>
                            <h5 class=" app-heading">About BackPain :-</h5>
                            <p className="para-text">  ➤ Pain in the low back can be a result of conditions affecting the body lumbar spine, intervertebral discs, ligaments around the spine & discs, spinal cord & nerve, muscles of the low back, internal organs of the pelvis and abdomen, and the skin covering the lumbar area.</p>
                            <p className="para-text">  ➤ Sandwiched between these two bony blocks are cushions of cartilage and elastic tissues called intervertebral discs, which act as a shock absorbers for the back & mobility would be impossible without vertebral discs.</p>
                            <p className="para-text">➤ When the bones are weakened, due to many causes, cartilage depreciation occurs, results in affecting of nerves that are interwoven through the spine column, as a result, blood circulation decreases and the nerve is effected in such a way that the pain radiates down the thigh and leg. When the pain increases, a state of pain changes in 5 stages called as slipped disc, bulging disc, degenerated disc, herniated disc, thinning disc & disc degeneration with osteophyte formation.</p>
                            <p className="para-text">➤ This back pain does not occur as a sudden. In our body, depending upon the weakness and other causes, day by day, increasing the pressure on bones and leads to cartilage depreciation, pain increases naturally and leads to severe form, sometimes this pain may comes due to severe blow on spine/waist by sudden fall & accidents.</p>
                            <p className="para-text"></p>
                            <p className="para-text"></p>
                            <p className="para-text"></p>

                            <h5 className='app-heading'>Causes :- </h5>
                            <ul className='treatment-ortho-ul'><li>The main causes of back pain are muscular tension, joint strain, lack of physical activity, improper posture of spine, sitting wrongly, moreover sitting improperly for long time, untimely food, incorrect nutrition, work & emotional stress, vitamin deficiency, over weight (as the back bears the weight of the entire body). Acute or chronic illness like kidney or prostate problems, female disorders, influenza & arthritis may also lead to back ache. </li>
                            </ul>
                            <h5 className='app-heading'>Symptoms :-</h5>
                            <ul className='treatment-ortho-ul'><li>Pain is usually felt either in the middle of the back or lower down. May spread to both sides of the waist & hips with acute pain, the patient are unable to move & is bed ridden. </li>
                                <li>About 90% of back ache patients suffer from cervical or lumbar spondylosis. It is a degenerative disorder in which the vertebral bone or the intervertebral disc bones becomes soft and loses shape. As a result of this, spine loses its flexibility.</li></ul>
                            <h5 className='app-heading'>Back Pain Incldes :-</h5>
                            <ul className='treatment-ortho-ul'><li>Lumbar spondylosis</li>
                                <li>Spinal muscular atrophy</li>
                                <li>Lumbar Spondylolisthesis</li>
                                <li>Ankylosing Spondylitis</li>
                                <li>Lumbar spinal Stenosis</li>
                                <li>Degenerative changes of spine</li>
                            </ul>
                            <h5 class=" app-heading">Treatments :-</h5>
                            <ul className='treatment-ortho-ul'><li>Temporary treatment for back pain by modern medical practices is just to reduce the pain temporarily and get relief in the form of tablets, injections and surgeries.</li>
                                <li>Permanent treatment for back pain by Kalpataru ayurveda is to release the disc from improper position and to reduce the space between the vertebrae by increasing the cartilage by providing the complete ayurvedic treatment in the form of</li>
                                <li>Ayurvedic internal medicine</li>
                                <li>Panchakarma</li>
                                <li>Upakarmas of panchakarma</li>
                                <li>Ahara niyama (dietary regimen)</li>
                                <li>Vyayama (yogic exercise)</li>
                                <li>Chairopractic</li>
                                <li>Marma Therpay </li>
                                <li>Massage Therapy </li>
                                <li>Consoling </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-6'>
                                <div className="ortho-left-side treat_side_card">
                                    <h4>Make An appointment </h4>
                                    <ul className='treat_ul'>
                                        <li> Monday-Friday <span> : 9:00 AM - 17.00 PM </span></li>
                                        <li>Saturday<span style={{ marginLeft: '42px' }}> : 9:00 AM - 16:00 PM</span></li>
                                        <li>Sunday <span style={{ marginLeft: '51px' }}> : Closed</span></li>
                                        <b> <li>Need Urgent Help ? <span>+919515621818</span></li></b>
                                    </ul>
                                    <div className="appointment-btn pt-10">
                                        <span></span>
                                        <a href="/register" className="main-btn treat_btn ">Enroll Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-6 pt-3'>
                                <div className='ortho-card-secondpart '>
                                    <div className='ortho-image-part'>
                                        <div className='image'>
                                            <img src="/images/Backpain/sideback1.jpg" style={{ "height": "200px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Back pain</h4>
                                        </div>
                                    </div><br />
                                    <div className='ortho-image-part '>
                                        <div className='image'>
                                            <img src="/images/Backpain/sideback2.jpg" style={{ "height": "200px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Back pain </h4>
                                        </div>
                                    </div><br />
                                    <div className='ortho-image-part '>
                                        <div className='image'>
                                            <img src="/images/Backpain/sideback4.jpg" style={{ "height": "200px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Back pain </h4>
                                        </div>
                                    </div><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>


    )
}
