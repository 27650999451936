import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'
import KneeOne from '../Container/KneeContainer/KneeOne'
import KneeTwo from '../Container/KneeContainer/KneeTwo'
import KneeThree from '../Container/KneeContainer/KneeThree'

export default function KneePain() {
  return (
    <React.Fragment>
      <SEO title="Kalpataru || KneePain" />
      <TopNav />
      <Breadcrumb image="/images/breadcrumb/KneePain.png" title="Home" content="KneePain" />
      <KneeOne />
      <KneeTwo />
      <KneeThree />
      <Footer />
    </React.Fragment>
  )
}
