import React from 'react'

function HeartCareThree() {
    return (
        <div>
            <div class="container pt-5">
                <div class="row">
                    <div class="col-md-8">
                        <h5 className='app-heading'>Common Symptoms Of  Heart Disease:-</h5>
                        <ul className='treatment-ortho-ul'>
                            <li>Anorexia (Aruchi), Dis-taste of mouth (Mukhavairasya), Nausea (Kaphotklesh)  </li>
                            <li> Increased thirst(Trishna), Dyspnoea (Shwas), Fainting (Murchha)   </li>
                            <li> Hiccough(Hikka), Chest pain (UrahShool), Cough (Kasa)   </li>
                            <li> Vomiting (Chhardi), Pallor/Cyanosis (Vivamata), Stupor (Pramoh)  </li>
                            <li> Coating inside throat (Galoplepa), Fever (Jwar), Productive Cough (KaphaShthivan)  </li>
                        </ul>
                        <div className='orthocare-desc'>
                            <h5 class=" app-heading">CARDIAC RISK FACTORS:</h5>
                            <p className="para-text">  ➤ Non Modifiable Risk Factors, Advancing age, Genetic predisposition. </p>
                            <p className="para-text">  ➤ <b>Modifiable Risk Factors</b> like Sedentary life style, Hypertension, Type-II Diabetes mellitus, Dyslipidemia / Lipid disorders, Overweight/Obesity, Metabolic syndrome, Smoking/alcohol consumption. </p>
                            <p className="para-text">  ➤ <b>Emerging Risk Factors </b> like Infections due to helicobacter pylori and Chlamydia pneumonia, Calcium supplementation, Vitamin D deficiency, Anti-phospholipids Syndrome, Premature Births/Low Birth weight. </p>
                            <p className="para-text">  ➤ <b>The Ayurvedic Guidelines For Modifiable Risk Factor:</b>like Madhumeha(Diabetes), Sthaulya (Obesity), Uchcharaktachap(Hypertension) and Medoroga (Dyslipidaemia)  </p>

                        </div>
                        <div className='row mt-2' >
                            <div className='col text-center mb-3 mt-2' >
                                <h4>Treatment Under Supervision Of  Dr. SRIMNANARAYANA, Ph.D.  B.A.M.S. | M.Sc., M.D. (Ayu) Rasashastra | Ph.D. (Ayu) Rasashastra </h4>
                            </div>
                        </div>
                        <div className='orthocare-desc'>
                            <h5 class=" app-heading">CONTRIBUTIONS:</h5>
                            <p className="para-text">
                                ➤ For the past 38 years, he has been actively performing conclusive diagnosis with modern tools and applications for various chronic and acute diseases such as CARDIAC, CARCINOMA, Leukemia, Respiratory troubles, ACUTE AND CHRONIC RENAL FAILURE, Liver disorders, Auto-immune diseases such as Psoriasis, Rheumatoid Arthritis, Gout, Gallstones, Renal calculi, Metabolic disorders like Diabetes Mellitus, Thyroidism, Pediatric, and Gynecological disorders, Impotence, Frigidity, INFERTILITY, and other Psychological Ailments
                            </p>

                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-6'>
                                <div className="ortho-left-side treat_side_card">
                                    <h4>Make An appointment </h4>
                                    <ul className='treat_ul'>
                                        <li> Monday-Friday <span> : 9:00 AM - 17.00 PM </span></li>
                                        <li>Saturday<span style={{ marginLeft: '42px' }}> : 9:00 AM - 16:00 PM</span></li>
                                        <li>Sunday <span style={{ marginLeft: '51px' }}> : Closed</span></li>
                                        <b> <li>Need Urgent Help ? <span>+919515621818</span></li></b>
                                    </ul>
                                    <div className="appointment-btn pt-10">
                                        <span></span>
                                        <a href="/register" className="main-btn treat_btn ">Enroll Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-6 pt-3'>
                                <div className='ortho-card-secondpart '>

                                    <div className='ortho-image-part'>
                                        <div className='image'>
                                            <img src="/images/HeartCare/HeartCare6.jpg" style={{ "height": "180px", "width": "293px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>HeartCare</h4>
                                        </div>
                                    </div><br />
                                    <div className='ortho-image-part '>
                                        <div className='image'>:
                                            <img src="/images/HeartCare/HeartCare7.jpg" style={{ "height": "180px", "width": "290px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>HeartCare</h4>
                                        </div>
                                    </div><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    )
}

export default HeartCareThree