import React from 'react'
import { Link } from 'react-router-dom'
import TeamData from '../../../Data/AboutData/MedicalTeam.json'

export default function MedicalTeamOne() {
    return (
        <div className='container-fluid' >
            <div className='row' >
                <div className='col' >
                    <div className="section_heading">
                        <h1>Kalpataru <span>Medical</span> Team</h1>
                    </div>
                </div>
            </div>
            <div className='row' >
                <div className='col medical-team-col ' >
                    <p>Kalpataru Ayurveda Hospitals Doctors Team is working under the Supervision of World Vedic Health Sciences Private Limited. Our doctor's team provides services throughout India and abroad both online and offline services.</p>
                    {/* <p>
                        Kalpataru Ayurveda hospitals have a demonstrated expertise in rendering a specialized treatment for spine-bone-neurocare-Pain Management. Besides rendering treatment, it has hospitals researched and developed different techniques for treating spine-bone-neurocare related to Neuro-muscular disorders like Lumbar spondylitis (Low back ache), Cervical spondylitis (Neck pain), Sciatica (Radiating pain), Spinal Muscular Atrophy, Ankylosing Spondylitis, Lumbar Spinal Stenosis, Degenerative changes of spine, Osteoarthritis (Knee joint pain), Rheumatoid arthritis (small joint pain), Metabolic arthritis (Gouty).and all Acute and hronic disorders.
                    </p>
                    <p>
                        We have Experienced team provide services. Special Treatments and consultation for Kidney cancer heart infertility pails fistula annual fissure ENT kidney, gall stones and for All Chronic Stages (Under supervision of 40 Years Experienced Ayurveda Doctors).
                    </p> */}
                </div>
            </div>
            <div className='row' style={{ 'justifyContent': 'center' }} >
                {
                    TeamData.map((d) => (
                        <div className='col-xl-9' >
                            <div className="card mb-3" >
                                <div className="row" >
                                    <div className="col-xl-3 col-md-3">
                                        <img src={d.image} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-xl-9 col-md-9">
                                        <div className="card-body">
                                            <h5 className="card-title medical-team-title"> {d.name} </h5>
                                            <h6 className='medical-team-text' > {d.text} </h6>
                                            <p className="card-text medical-team-des "> {d.des} </p>


                                            <Link className='medical-team-link' to="/register" > {d.link} </Link>
                                            {/* <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5 mb-5'>
                                <p className="card-text medical-team-des "> {d.des1} </p>
                                <p className="card-text medical-team-des "> {d.des2} </p>
                                <p className="card-text medical-team-des "> {d.des3} </p>
                                <p className="card-text medical-team-des "> {d.des4} </p>
                                <p className="card-text medical-team-des "> {d.des5} </p>
                                <p className="card-text medical-team-des "> {d.des6} </p>
                            </div>
                            <div className='mt-5 mb-5' style={{ borderTop: '1px solid gray', width: '100%' }} >  </div>

                        </div>
                    ))
                }

            </div>
        </div>
    )
}
