const validateEmail = RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
// const validatePassword = RegExp(
//   /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/
// );
const mobile = RegExp(
    /([0-9])/
);
export default function RegisterValidation(values) {
    let errors = {};

    if (!values.fullName) {
        errors.name = "Enter Your Full Name";
    } else if (values.fullName.length < 6) {
        errors.fullName = "Name should contain minimum 6 characters";
    }

    if (!values.email) {
        errors.email = "Email is required";
    } else if (!validateEmail.test(values.email)) {
        errors.email = "Email is invalid";
    }

    if (!values.location) {
        errors.location = "This Field is Required";
    }

    if (!values.appointmentDate) {
        errors.appointmentDate = "This Field is Required";
    }

    if (!values.comment) {
        errors.comment = "This Field is Required";
    }

    if (!values.mobile) {
        errors.mobile = "Enter Your Mobile Number";
    } else if (values.mobile.length < 10) {
        errors.mobile = "Mobile Number Should Contain Minimum 10 Characters ";
    } else if (values.mobile.length > 10) {
        errors.mobile = "Mobile Number Should Contain Maximum 10 Characters ";
    } else if (!mobile.test(values.mobile)) {
        errors.mobile = "Enter Valid Mobile Number";
    }

    return errors;
}
