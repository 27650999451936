import React from 'react'

function SummerCampOne() {
    return (
        <div className='container' >
            <div className="section_heading">
                <h1>SUMMER <span> CAMP </span> INDIA</h1>
            </div>
            <div className='row mb-5' >
                <div className='summer_camp_p'>
                    <p>
                        Participants enrolled for this SUMMER CAMP INDIA, will experience a Home Away. From Home, an entirely new world to live in for 3 weeks fearlessly under the guidance of experts getting to learn all that is required for venturing into these places ever in life. We have been conducting summer camps since 1999, and as usual, our Experienced, Young, and dynamic Team will work for this summer camp. Kriya Yoga Samsthan had successfully conducted various programs on a large scale where 500 to 5000 participants from Nationwide gather at one place and comparatively this camp is a small assignment to work out for the management team. When students once get admitted to the camp, we divide them according to their age groups and initiate training. Daily routine kick-starts with a wake-up whistle early in the morning at 5:00 am and concludes at 8:30 pm and irrespectively every activity is mandatory to all participants.
                    </p>
                    {/* <p>
                        It Greatly Contributes to The Health and well being of citizens thus holding the ancient heritage, Vedic Culture and Traditional Educational Systems Intact at Kriya Yoga Samsthan, we firmly believe in giving your child what is best for their life and not what they expect best from us! At the GURUKUL, we take care of your child like you do.
                    </p> */}
                </div>
            </div>
            <div className='row mb-5' >
                <div className='summer_camp_p'>
                    <h3 className='summer_camp_title' >
                        6 REASONS WHY YOU SHOULD SEND YOUR CHILD TO THE RESIDENTIAL SUMMER CAMP  ?
                    </h3>
                    <p>
                        It is a Single Destination that gets kids outside having fun while teaching them lifelong skills.  It is a wonderful way to keep your children occupied in a way that helps them continue learning during the summer holidays.
                    </p>
                    <div className='mt-3 summer_camp_span' >
                        <span className='span_text'> || UNDERSTANDING OF OUR VEDIC CULTURE   ||  EXPLORING NEW ADVENTURES||  </span> <div></div>
                        <span className='span_text'> || NURTURING NEW FRIENDSHIPS||    || ABLE TO SOCIALIZE WITH OTHERS|| </span> <div></div>
                        <span className='span_text'> || DEVELOPING SELF-CONFIDENCE || A VARIETY OF SPORTS ACTIVITIES ||  </span> <div></div>
                        <span className='span_text'> || Teach Them Young - The Yogic Way || Do Something Big this Summer ||  </span> <div></div>
                        <span className='span_text'> || A New Rhythm in the Soul Begins Here..! || </span> <div></div>
                        <span className='span_text'> || Come on! Let’s go Camping and Gain a Holistic Experience. || </span> <div></div>
                        <span className='span_text'> || The Time Camping isn’t spent, rather it's invested || </span> <div></div>
                        <span className='span_text'> || Give Your Kids the Experience of Yogic Science & Medical Science  || </span> <div></div>
                        <span className='span_text'> || We will teach them Moral Values & Patriotism  || </span> <div></div>
                    </div>
                    <div className='text-center mt-3'>



                    </div>
                    <div className='mt-5 summer_camp_link'>
                        FOR MORE DETAILS CAN VISIT WEBSITE : <a href='https://www.summercampindia.in/' target="_blank" > https://www.summercampindia.in/   </a>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SummerCampOne