import React from 'react'

function KidneyOne() {
    return (
        <div>
            <div className='section_heading'>
                <h1>Kalpataru <span>Kidney Care</span> Treatment</h1>
            </div>

            <div className="container homeabout-main pt-5">

                <div className="row justify-content-between">

                    <div className=" col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 homeabout-col">
                        <p> ➤ The kidneys are central to homeostasis Through exquisite sensory mechanisms they regulate blood pressure, water, sodium, potassium, acidity , bone, minerals  and hemoglobin. But their core function is the excretion of the waste products of metabolism in urine. About 22 % of cardiac output goes to the kidneys and about 20 % of the plasma is filtered, producing about 170 L of glomerular filtrate per day. Ninety-nine percent of this is reabsorbed as it flows along the nephrons so only about 1.5 L of urine is produced per day.</p>
                        <p> ➤ Kidney stone is one of the most common diseases of the urinary system. It has peculiar tendency of recurrence even after surgery. Kidney stones are a result of more minerals or salts in urine.</p>

                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <img className="homeabout-image" src="https://img.freepik.com/free-vector/gradient-urology-illustration_23-2149751880.jpg?w=996&t=st=1671511287~exp=1671511887~hmac=97b4965088b63025bb67844f71aeb1e75edc109124cf4a82a2885ac393efa1bc" height="290px" alt="Loading.." />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default KidneyOne