import React from 'react'

function CancerThree() {
    return (
        <div>
            <div class="container pt-5">
                <div class="row">
                    <div class="col-md-8">
                        <div className='orthocare-desc'>
                            <p className="para-text">  ➤ A Malignant tumor does not suppurate because it contains an excess of Kapha and adipose tissue, thus forming firm little balls.</p>

                            <div>
                                <h5 class=" app-heading">What can Ayurveda do to improve the ailments of a cancer patient? </h5>
                                <p className="para-text">  ➤ The resulting vision of Ayurvedic pathology offers the possibility to develop customized treatment modules catering to each individual according to causal factors. The causal factor will be one of the three Doshas that is prevented from correct functional communication due to lifestyle habits, diet, and secondary effects of medication, environmental chemicals, or psychosomatic reasons. The possible causes for the disruption of Dosha's function are unlimited. Once the causes are identified, they should be removed, as the cause of Dosha pathology will prevent the re-establishment of homeostasis.</p>

                                <ul className='treatment-ortho-ul'>
                                    <li> <b> Curative: </b> Medicines that have shown encouraging results for cancer.  </li>
                                    <li> <b> Supportive: </b> Includes Ayurveda in allopathic treatment to combat their side effects and improve quality of life.   </li>
                                    <li> <b> Prophylactic: </b> Includes Swasthavritta (Hygiene), Ahara (Diet), and Vihara (Lifestyles) which are especially required to prevent cancer. </li>
                                    <li> <b> Palliative: </b> Various groups of drugs to increase immunity (OJAS - The underlying strength) and drugs known for their anti-inflammatory properties. </li>
                                </ul>
                            </div>

                            <div className='row mt-2' >
                                <div className='col text-center mb-3 mt-2' >
                                    <h4>Treatment Under Supervision Of  Dr. SRIMNANARAYANA, Ph.D.  B.A.M.S. | M.Sc., M.D. (Ayu) Rasashastra | Ph.D. (Ayu) Rasashastra </h4>
                                </div>
                            </div>

                            <h5 class=" app-heading">CONTRIBUTIONS:-</h5>
                            <p className="para-text">  ➤ For the past 38 years, he has been actively performing conclusive diagnosis with modern tools and applications for various chronic and acute diseases such as CARDIAC, CARCINOMA, Leukemia, Respiratory troubles, ACUTE AND CHRONIC RENAL FAILURE, Liver disorders, Auto-immune diseases such as Psoriasis, Rheumatoid Arthritis, Gout, Gallstones, Renal calculi, Metabolic disorders like Diabetes Mellitus, Thyroidism, Pediatric, and Gynecological disorders, Impotence, Frigidity, INFERTILITY, and other Psychological Ailments.</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-6'>
                                <div className="ortho-left-side treat_side_card">
                                    <h4>Make An appointment </h4>
                                    <ul className='treat_ul'>
                                        <li> Monday-Friday <span> : 9:00 AM - 17.00 PM </span></li>
                                        <li>Saturday<span style={{ marginLeft: '42px' }}> : 9:00 AM - 16:00 PM</span></li>
                                        <li>Sunday <span style={{ marginLeft: '51px' }}> : Closed</span></li>
                                        <b> <li>Need Urgent Help ? <span>+919515621818</span></li></b>
                                    </ul>
                                    <div className="appointment-btn pt-10">
                                        <span></span>
                                        <a href="/register" className="main-btn treat_btn ">Enroll Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-6 pt-3'>
                                <div className='ortho-card-secondpart '>

                                    <div className='ortho-image-part'>
                                        <div className='image'>
                                            <img src="/images/CancerCare/CancerCare6.jpg" style={{ "height": "180px", "width": "293px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>CancerCare</h4>
                                        </div>
                                    </div><br />
                                    <div className='ortho-image-part'>
                                        <div className='image'>
                                            <img src="/images/CancerCare/CancerCare7.jpg" style={{ "height": "180px", "width": "293px" }} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>CancerCare</h4>
                                        </div>
                                    </div><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    )
}

export default CancerThree