import React from 'react'

function HeartCareTwo() {
    return (

        <section className="Kalpataru-second-ortho pt-5">

            <div className="container-fluid">

                <div className="row mt-30">
                    <div className="col-md-3 col-sm-6">
                        <a href=""> <div className="ortho-card">
                            <img src="/images/HeartCare/HeartCare1.jpg" style={{ "height": "180px" }} alt="Image" />
                            <div className="ortho-content">
                                <p>HeartCare</p>
                            </div>

                        </div></a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href=""><div className="ortho-card">
                            <img src="/images/HeartCare/HeartCare2.jpg" style={{ "height": "180px" }} alt="Image" />
                            <div className="ortho-content">
                                <p>HeartCare</p>
                            </div>

                        </div></a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href=""><div className="ortho-card">
                            <img src="/images/HeartCare/HeartCare4.jFIF" style={{ "height": "180px" }} alt="Image" />
                            <div className="ortho-content">
                                <p>HeartCare</p>
                            </div>

                        </div></a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href=""><div className="ortho-card">
                            <img src="/images/HeartCare/HeartCare3.jpg" style={{ "height": "180px" }} alt="Image" />
                            <div className="ortho-content">
                                <p>HeartCare</p>
                            </div>

                        </div></a>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default HeartCareTwo