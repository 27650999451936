import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'
import Infertility1 from '../Container/Infertilitytreatment/Infertility1'
import InfertilityTwo from '../Container/Infertilitytreatment/InfertilityTwo'
import InfertilityThree from '../Container/Infertilitytreatment/InfertilityThree'

export default function Infertility() {
  return (
    <React.Fragment>
      <SEO title="Kalpataru || Infertility" />
      <TopNav />
      <Breadcrumb image="/images/breadcrumb/Infertility.jpg" title="Home" content="Infertility" />
      <Infertility1 />
      <InfertilityTwo />
      <InfertilityThree />
      <Footer />
    </React.Fragment>
  )
}
