import React from 'react'

export default function InfertilityTwo() {
    return (

        <section className="Kalpataru-second-ortho pt-5">

            <div className="container-fluid">

                <div className="row mt-30">
                    <div className="col-md-3 col-sm-6">
                        <a href=""> <div className="ortho-card">
                        <img src="/images/Infertility/Infertility1.jpg" style={{"height":"180px"}} alt="Image" />
                            <div className="ortho-content">
                                <p>Infertility</p>
                            </div>

                        </div></a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href=""><div className="ortho-card">
                        <img src="/images/Infertility/Infertility2.jpg" style={{"height":"180px"}} alt="Image" />
                            <div className="ortho-content">
                                <p>Infertility</p>
                            </div>

                        </div></a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href=""><div className="ortho-card">
                        <img src="/images/Infertility/Infertility3.jpg" style={{"height":"180px"}} alt="Image" />
                            <div className="ortho-content">
                                <p>Infertility</p>
                            </div>

                        </div></a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href=""><div className="ortho-card">
                        <img src="/images/Infertility/Infertility4.jpg" style={{"height":"180px"}} alt="Image" />
                            <div className="ortho-content">
                                <p>Infertility</p>
                            </div>

                        </div></a>
                    </div>
                </div>
            </div>

        </section>
    )
}
