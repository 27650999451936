import React from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'

export default function TopNav() {
    return (
        <div className='sticky-top' >
            <Navbar fixed="top" className="navbar" collapseOnSelect expand="lg" >
                <Container fluid>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Brand href="/"> <img className="logo" src="/images/logo/logo.png" /> </Navbar.Brand>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav defaultActiveKey="/" className="me-auto nav ">
                            <NavLink className="nav-link" as={Link} to='/' >Home</NavLink>
                            <NavDropdown title="Kalpataru" id="collasible-nav-dropdown" renderMenuOnMount={true}>
                                <NavDropdown.Item as={Link} to='/about'> Kalpataru </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/kalpataru/hospitals'> Kalpataru Hospitals</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/kalpataru/stores'> Kalpataru Stores</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/kalpataru/medical/team'>Medical Team</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/kalpataru/facilities'>Our Facilities</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Our Services" id="collasible-nav-dropdown" renderMenuOnMount={true}>
                                <NavDropdown.Item as={Link} to='/ourservices'>Kalpataru Services </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/iri/diagnosis'>IRI Diagnosis </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Treatments" id="collasible-nav-dropdown" renderMenuOnMount={true}>
                                <NavDropdown.Item as={Link} to='/Treatments/SciaticaPain'>Sciatica Pain </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/Tretments/BackPain'>Back pain</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/Treatments/Neckpain'>Neck Pain</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/Treatments/Paralysis'>Paralysis</NavDropdown.Item>
                                {/* <NavDropdown.Item as={Link} to='/panch/upachar'>Child Care</NavDropdown.Item> */}
                                <NavDropdown.Item as={Link} to='/Treatments/Obesity'>Obesity</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/Treatments/Diabetes'>Diabets</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/Treatments/Infertility'>Infertility</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/Treatments/HeartCare'>Heart Care</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/Treatments/CancerCare'>Cancer Care</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/Treatments/KidneyCare'>Kidney Care</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/Treatments/KneePain'>Knee Pain</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/Treatments/Piles'>Piles </NavDropdown.Item>
                            </NavDropdown>
                            {/* <NavDropdown title="Our Products" id="collasible-nav-dropdown" renderMenuOnMount={true}>
                                <NavDropdown.Item as={Link} to='/general/product'>General Products</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/medical/product'>Medical Products</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/organic/product'>Organic Products</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/untensil'>Utensils</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/herbal/plants'>Herbal Plants</NavDropdown.Item>
                            </NavDropdown> */}
                            <NavDropdown title="Events" id="collasible-nav-dropdown" renderMenuOnMount={true}>
                                <NavDropdown.Item as={Link} to='/summer/camp'> Summer Camp India </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/ayurveda/expo'> Ayurveda Expo </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/yogacamp'> Yoga Camp</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/shishu/swarna'> Shishu Samskara Maha Yagna  </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/swarna/bindu'>Swarna Bindu Prashana </NavDropdown.Item>
                               
                            </NavDropdown>
                            <NavLink className="nav-link" as={Link} to='/franchise'>Franchise</NavLink>
                            <NavLink className="nav-link" as={Link} to='/courses'>Courses</NavLink>
                            <NavLink className="nav-link" as={Link} to='/contact'>Contact Us</NavLink>

                        </Nav>
                        <Nav>
                            {/*   <NavLink className="nav-link-buy" as={Link} to='/buy/products'> Register Now </NavLink> */}
                            <NavLink className="nav-link-buy" as={Link} to='/register'>Online Consultation </NavLink>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}
